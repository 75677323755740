<template>
  <b-container>
    <b-row class="mb-1" v-if="!statusDone">
      <b-col cols="7">
        <b-input-group prepend="Search creditor" class="mt-3">
          <v-select
            :disabled="statusDone"
            taggable
            :options="optionsCreditor"
            placeholder="Search..."
            @search="onSearch"
            style="flex: 1; border-radius: 0px"
            v-model="creditorName"
          >
            <template slot="no-options"> search creditors by name... </template>
          </v-select>
          <b-input-group-append>
            <b-button v-if="!statusDone" @click="addAccount" variant="primary"
              >ADD</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="5">
        <slot name="others" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <TableAccountsManual
          ref="tableAccountManual"
          :creditorName.sync="creditorName"
          @deleteAccounts="deleteAccounts"
          :crAccountsStore="crAccountsStore"
          :statusDone="statusDone"
          @loadAccounts="loadAccounts"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container>
          <b-button
            variant="success"
            class="float-right ml-1"
            @click="doneAccount"
            v-if="!statusDone"
          >
            <strong>DONE</strong>
          </b-button>
          <b-button
            variant="primary"
            class="float-right"
            @click="saveAccount"
            v-if="!statusDone && canSave"
          >
            <strong>SAVE</strong>
          </b-button>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import TableAccountsManual from "@/views/commons/components/request-ncr/views/modals/AddAccountsManual/TableAccountsManual.vue";
import DebtSolutionService from "@/views/debt-solution/services/debt-solution.service.js";

export default {
  name: "AddAccountManual",
  components: {
    TableAccountsManual,
  },
  props: {
    crAccountsStore: {
      type: Array,
      required: true,
    },
    statusDone: {
      type: Boolean,
      required: true,
    },
    canSave: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      optionsCreditor: [],
      cr: {
        date: "",
        score: [],
        personal: [],
        sumary: [],
        inquires: [],
        account: [],
        public: [],
        done: false,
      },
      creditorName: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    loadAccounts(value) {
      this.$emit("loadAccounts", value);
    },
    onSearch(search, loading) {
      if (search.length > 0) {
        loading(true);
        this.searchCreditors(loading, search, this);
      } else {
        this.optionsCreditor = [];
      }
    },
    searchCreditors: _.debounce(async function (loading, search, vm) {
      let creditors = [];
      const data = await DebtSolutionService.getCreditorsDS({
        q: search,
      });
      data.forEach((item) => {
        creditors.push(item.value);
      });
      vm.optionsCreditor = creditors.filter(
        (item, index) => creditors.indexOf(item) === index
      );
      loading(false);
    }, 100),
    async addAccount() {
      try {
        await this.methodTableAccount().addAccount();
      } catch (ex) {
        this.showGenericToast({
          text: ex.message,
          variant: "warning",
          icon: "AlertTriangleIcon",
          title: "ADD ACCOUNTS",
        });
      }
    },
    methodTableAccount() {
      let tb = this.$refs.tableAccountManual;
      const { addAccount, verificateItem, accounts } = tb;
      return {
        verificateItem,
        addAccount,
        accounts,
      };
    },
    deleteAccounts(accounts) {
      try {
        this.cr.account = accounts;
        this.cr.done = false;
        this.$emit("deleteAccounts", this.cr);
      } catch (ex) {}
    },
    async saveAccount() {
      try {
        this.addPreloader();
        this.cr.account = this.methodTableAccount().accounts;
        this.cr.done = false;
        if (this.cr.account.length === 0)
          throw new Error("You should add at least one account");
        this.$emit("saveAccounts", this.cr);
      } catch (ex) {
        this.removePreloader();
        this.showGenericToast({
          text: ex.message,
          variant: "warning",
          icon: "AlertTriangleIcon",
          title: "Save",
        });
      }
    },
    async doneAccount() {
      try {
        await this.methodTableAccount().verificateItem();
        const response = await this.showConfirmSwal(
          "Are you sure to complete the action?",
          "You won't be able to add more accounts"
        );
        if (!response.isConfirmed) return false;
        this.addPreloader();
        this.cr.account = this.methodTableAccount().accounts;
        if (this.cr.account.length === 0)
          throw new Error("You must add at least one account");
        this.cr.done = true;
        this.$emit("doneAccounts", this.cr);
      } catch (ex) {
        this.removePreloader();
        this.showGenericToast({
          text: ex.message,
          variant: "warning",
          icon: "AlertTriangleIcon",
          title: "Done",
        });
      }
    },
  },
};
</script>
<style lang="scss">
#detail-account-manual {
  tbody {
    input.valid {
      border: none;
    }
    input.is-invalid {
      border-radius: 0;
    }
  }
}
.lbl-transparent {
  color: transparent !important;
}
</style>
