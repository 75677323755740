var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"table__accounts__manual"}},[_c('b-table-simple',{attrs:{"small":"","caption-top":"","responsive":"","bordered":"","id":"detail-account-manual"}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"td-left text-right header-width col-2"}),_c('b-th',{staticClass:"text-center col-3 v-middle"},[_c('span',{staticClass:"mr-1"},[_vm._v("TransUnion")]),_c('b-img',{attrs:{"src":require('@/assets/images/icons/transunion.png')}})],1),_c('b-th',{staticClass:"text-center col-3 v-middle"},[_c('span',{staticClass:"mr-1"},[_vm._v("Experian")]),_c('b-img',{attrs:{"src":require('@/assets/images/icons/experian.png')}})],1),_c('b-th',{staticClass:"text-center col-3 v-middle"},[_c('span',{staticClass:"mr-1"},[_vm._v("Equifax")]),_c('b-img',{attrs:{"src":require('@/assets/images/icons/equifax.png')}})],1)],1)],1),_c('b-tbody',[_vm._l((_vm.accountsFormat),function(element,indexAccount){return [(!_vm.statusDone)?_c('b-tr',{key:indexAccount,class:_vm.isDarkSkin ? 'bg-dark' : 'bg-secondary-table-manual'},[_c('b-th',[(!_vm.statusDone)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('DELETE ACCOUNT'),expression:"'DELETE ACCOUNT'",modifiers:{"hover":true,"right":true}}],staticClass:"cursor-pointer ml-1",attrs:{"variant":"light-danger"}},[_c('feather-icon',{staticStyle:{"height":"15px","width":"15px"},attrs:{"icon":"TrashIcon"},on:{"click":function($event){return _vm.deleteAccount(indexAccount, element)}}})],1):_vm._e()],1),_c('b-th',[(_vm.copyBureau[indexAccount][0].length != 9 && !_vm.statusDone)?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.copyAttachment(indexAccount, 0)}}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}}),_vm._v(" Copy ")],1):_vm._e(),(
                _vm.bureauCopyPaste.index === indexAccount &&
                _vm.bureauCopyPaste.bureau != 0
              )?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.pasteAttachment(indexAccount, 0)}}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}}),_vm._v(" Paste")],1):_vm._e(),(_vm.copyBureau[indexAccount][0].length != 9 && !_vm.statusDone)?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.deleteItem(indexAccount, 0)}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"16"}}),_vm._v(" Clear")],1):_vm._e()],1),_c('b-th',[(_vm.copyBureau[indexAccount][1].length != 9 && !_vm.statusDone)?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.copyAttachment(indexAccount, 1)}}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}}),_vm._v(" Copy ")],1):_vm._e(),(
                _vm.bureauCopyPaste.index === indexAccount &&
                _vm.bureauCopyPaste.bureau != 1
              )?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.pasteAttachment(indexAccount, 1)}}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}}),_vm._v(" Paste")],1):_vm._e(),(_vm.copyBureau[indexAccount][1].length != 9 && !_vm.statusDone)?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.deleteItem(indexAccount, 1)}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"16"}}),_vm._v(" Clear")],1):_vm._e()],1),_c('b-th',[(_vm.copyBureau[indexAccount][2].length != 9 && !_vm.statusDone)?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.copyAttachment(indexAccount, 2)}}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}}),_vm._v(" Copy ")],1):_vm._e(),(
                _vm.bureauCopyPaste.index === indexAccount &&
                _vm.bureauCopyPaste.bureau != 2
              )?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.pasteAttachment(indexAccount, 2)}}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}}),_vm._v(" Paste")],1):_vm._e(),(_vm.copyBureau[indexAccount][2].length != 9 && !_vm.statusDone)?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.deleteItem(indexAccount, 2)}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"16"}}),_vm._v(" Clear")],1):_vm._e()],1)],1):_vm._e(),_c('b-tr',{key:indexAccount + 100 * 1999},[_c('b-th',[_vm._v("Account Name")]),_c('b-th',{staticClass:"text-center",attrs:{"colspan":"3"}},[_c('v-select',{attrs:{"options":_vm.dataCreditorsDS,"disabled":_vm.statusDone},on:{"search":_vm.onSearch},model:{value:(element.creditor_name),callback:function ($$v) {_vm.$set(element, "creditor_name", $$v)},expression:"element.creditor_name"}})],1)],1),_vm._l((element.headers),function(field,indexHeader){return [_c('b-tr',{key:((field.key) + "-" + indexAccount),style:({
              display: field.visible
                ? field.showDetail
                  ? ''
                  : 'none'
                : 'none',
            })},[_c('td',[(field.key === 'show_detail')?[_c('b-badge',{staticStyle:{"cursor":"pointer"},attrs:{"variant":"light-primary"},on:{"click":function($event){return _vm.showDetailField(element, indexAccount, indexHeader)}}},[_vm._v(" "+_vm._s(element.showDetail ? "Hide detail" : "Show Detail")+" ")])]:[(_vm.addOptions.includes(field.key) && !_vm.statusDone)?[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._v(" "+_vm._s(field.label)+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Add new comment'),expression:"'Add new comment'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer text-primary",attrs:{"size":"20","icon":"PlusCircleIcon"},on:{"click":function($event){return _vm.openModalAddAccounts(field)}}})],1)]:[_vm._v(" "+_vm._s(field.label)+" ")]]],2),_vm._l((element.account),function(item,indexItem){return [_c('td',{key:indexItem,staticClass:"p-0"},[_c('validation-observer',{ref:("tableForm-" + indexAccount + "-" + indexItem),refInFor:true},[(field.key != 'show_detail')?_c('InputItemAccountVue',{key:indexAccount + indexItem + 1000,attrs:{"field":field,"item":item,"disabled":_vm.statusDone},scopedSlots:_vm._u([{key:"a_type",fn:function(ref){
            var errors = ref.errors;
            var field = ref.field;
            var item = ref.item;
return [_c('v-select',{staticClass:"v-select-manual",class:errors[0] ? 'is-invalid' : 'valid',attrs:{"options":_vm.dataAccountType,"label":"text","reduce":function (option) { return option.value; },"placeholder":field.placeholder,"disabled":_vm.statusDone},model:{value:(item[field.key].value),callback:function ($$v) {_vm.$set(item[field.key], "value", $$v)},expression:"item[field.key].value"}})]}},{key:"a_status",fn:function(ref){
            var errors = ref.errors;
            var field = ref.field;
            var item = ref.item;
return [_c('v-select',{staticClass:"v-select-manual",class:errors[0] ? 'is-invalid' : 'valid',attrs:{"options":_vm.dataAccountStatus,"label":"text","reduce":function (option) { return option.value; },"placeholder":field.placeholder,"disabled":_vm.statusDone},model:{value:(item[field.key].value),callback:function ($$v) {_vm.$set(item[field.key], "value", $$v)},expression:"item[field.key].value"}})]}},{key:"comments",fn:function(ref){
            var errors = ref.errors;
            var field = ref.field;
            var item = ref.item;
return [_c('v-select',{staticClass:"v-select-manual",class:errors[0] ? 'is-invalid' : 'valid',attrs:{"multiple":"","options":_vm.dataComments,"label":"text","reduce":function (option) { return option.value; },"placeholder":field.placeholder,"disabled":_vm.statusDone},model:{value:(item[field.key].value),callback:function ($$v) {_vm.$set(item[field.key], "value", $$v)},expression:"item[field.key].value"}})]}},{key:"a_type_detail",fn:function(ref){
            var errors = ref.errors;
            var field = ref.field;
            var item = ref.item;
return [_c('v-select',{staticClass:"v-select-manual",class:errors[0] ? 'is-invalid' : 'valid',attrs:{"options":_vm.dataAccountTypeDetail,"label":"text","reduce":function (option) { return option.value; },"placeholder":field.placeholder,"disabled":_vm.statusDone},model:{value:(item[field.key].value),callback:function ($$v) {_vm.$set(item[field.key], "value", $$v)},expression:"item[field.key].value"}})]}}],null,true)}):_vm._e()],1)],1)]})],2)]})]})],2)],1),(_vm.rowSelected)?_c('ModalAddData',{attrs:{"keySelected":_vm.rowSelected},on:{"closeModal":function($event){_vm.rowSelected = null},"loadAddedOptions":_vm.loadAddedOptions}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }