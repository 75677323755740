<template>
  <div>
    <b-modal
      :title="
        item.status_id == 3
          ? 'ATTEMPT DETAILS'
          : `NEW ATTEMPT TO GET NCR (${item.type_ncr})`
      "
      v-model="ownControl"
      size="xlg"
      modal-class="modal-primary"
      @hidden="closeMe"
      title-tag="h3"
      no-close-on-backdrop
      no-close-on-esc
      scrollable
    >
      <ValidationObserver ref="formNcrAttempt">
        <div class="container">
          <PersonalInformation
            :showDocument.sync="show.document"
            :item.sync="item"
            :isClient="isClient"
            @getDocument="getDocument"
            @viewDocument="viewDocument"
          />

          <div v-if="item.status_id == 3">
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100">
                        <span>CARD NAME</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :value="item.cardholdername"
                      type="text"
                      readonly
                    />
                  </b-input-group>
                </b-form-group>

                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend class="w-35">
                      <b-input-group-text class="w-100">
                        <span>CARD NUMBER</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :value="item.cardnumber"
                      type="text"
                      readonly
                    />
                  </b-input-group>
                </b-form-group>

                <b-input-group>
                  <b-input-group-prepend class="w-35">
                    <b-input-group-text class="w-100">
                      <span>PLATFORM</span>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <span
                    style="'border-color:rgba(255, 255, 255, 0.4)'; "
                    :style="
                      isDarkSkin
                        ? 'background: #17171A;opacity: 0.3;color:white'
                        : 'background: #efefef'
                    "
                    class="form-control"
                  >
                    <img
                      :src="
                        item.plataform_ico ? baseUrl + item.plataform_ico : ''
                      "
                      :alt="item.plataform_name"
                    />
                    {{ item.plataform_name }}
                  </span>
                </b-input-group>
              </b-col>
            </b-row>
          </div>

          <div v-else>
            <b-row>
              <b-col cols="6">
                <ValidationProvider
                  name="source"
                  :rules="`required`"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Select a source"
                    label-class="font-weight-bolder"
                  >
                    <b-input-group>
                      <b-input-group-prepend class="w-25">
                        <b-input-group-text class="w-100">
                          <span>SOURCE</span>
                          <feather-icon
                            icon="HelpCircleIcon"
                            class="text-info ml-1"
                            size="18"
                            id="info_tooltip"
                          />

                          <b-tooltip target="info_tooltip" placement="right">
                            <div class="text-left">
                              Sources used for previous attempts will be
                              unabled.
                              {{
                                !isAutomatic
                                  ? "The suggested source will be selected by default, if any."
                                  : ""
                              }}
                            </div>
                          </b-tooltip>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <v-select
                        :options="attempt.sources"
                        label="text"
                        class="w-75 v-select-attempt"
                        :reduce="(option) => option.value"
                        v-model="attempt.source.value"
                        :class="
                          errors.length > 0 && !reloadValidation
                            ? 'border-danger'
                            : ''
                        "
                        :selectable="(option) => option.disabled"
                      >
                        <template v-slot:option="option">
                          <img
                            v-if="isAutomatic"
                            :src="option.icon"
                            :alt="option.text"
                            class="img-logo"
                          />
                          {{ option.text }}
                        </template>
                        <template #selected-option="option">
                          <img
                            v-if="isAutomatic"
                            :src="option.icon"
                            :alt="option.text"
                            class="img-logo"
                          />
                          {{ option.text }}
                        </template>
                      </v-select>
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col v-if="!isAutomatic && getSource == 'REALTOR'" cols="2">
                <b-form-group label="Generate" label-class="font-weight-bolder">
                  <b-button
                    variant="outline-primary"
                    class="w-100"
                    @click.prevent="generateSignatureAuth"
                    >Signature Auth</b-button
                  >
                </b-form-group>
              </b-col>

              <b-col cols="4" v-if="!isAutomatic && attempt.fileId">
                <b-form-group label="File ID" label-class="font-weight-bolder">
                  <b-input-group>
                    <b-form-input :value="attempt.fileId.file_name" disabled />
                    <b-input-group-append>
                      <b-button
                        variant="outline-secondary"
                        class="py-0"
                        v-b-tooltip.hover.top="'Download'"
                        @click="downloadFileId(attempt.fileId.route)"
                      >
                        <feather-icon icon="DownloadIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- CARDS -->
              <b-col cols="12">
                <b-form-group
                  label="Credit Card"
                  label-class="font-weight-bolder"
                >
                  <b-form-radio-group
                    v-model="attempt.type_card"
                    :options="[
                      { value: 1, text: 'Of client' },
                      { value: 2, text: 'AMG cards' },
                    ]"
                    @change="typeCard"
                    button-variant="outline-primary"
                    buttons
                    size="sm"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-table
                  sticky-header
                  show-empty
                  :items="attempt.cards"
                  :fields="visibleFields"
                  responsive="sm"
                  ref="cards-table"
                  :class="{ 'border-danger': errorTable }"
                >
                  <template #empty>
                    <p class="text-center my-2">
                      {{
                        attempt.type_card == 2
                          ? "Choose AMG or CRM cards"
                          : "No cards were found"
                      }}
                    </p>
                  </template>

                  <template #cell(radio)="data">
                    <ValidationProvider
                      name="card"
                      :rules="`required`"
                      ref="validateCreditCard"
                      v-slot="{ errors }"
                    >
                      <b-form-radio
                        v-model="attempt.card"
                        :value="data.item.id"
                        @input="validOptionTable(data.item)"
                        :disabled="checkExpiredCard(data.item)"
                        plain
                      />
                      <span style="display: none">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </template>

                  <template #cell(cardnumber)="data">
                    {{
                      data.item.showFull
                        ? data.item.originalNumber
                        : "XXXX-XXXX-XXXX-" + data.item.cardnumber
                    }}
                    <feather-icon
                      v-if="!data.item.showSpinner"
                      :icon="creditCardToggleIcon(data.item)"
                      class="cursor-pointer ml-1"
                      :class="
                        data.item.showFull ? 'text-danger' : 'text-success'
                      "
                      size="16"
                      @click="onToggleCreditCard(data.item)"
                    />
                    <b-spinner v-else small class="ml-1" />
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <ValidationProvider
                  name="answer"
                  :rules="`required`"
                  v-slot="{ errors }"
                >
                  <b-form-group>
                    <b-input-group>
                      <b-input-group-prepend class="w-75">
                        <b-input-group-text class="w-100">
                          WAS THE CR OBTAINED?
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <div class="border-yesornot text-center w-25">
                        <b-form-radio-group
                          class="radio-yesornot"
                          v-model="attempt.result"
                          :options="[
                            { value: 'SUCCESS', text: 'Yes' },
                            { value: 'FAILED', text: 'No' },
                          ]"
                          :button-variant="
                            attempt.result !== ''
                              ? 'outline-primary'
                              : errors[0] && !reloadValidation
                              ? 'outline-danger'
                              : 'outline-secondary'
                          "
                          buttons
                          style="width: 100%"
                        >
                        </b-form-radio-group>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                v-if="
                  attempt.result === 'SUCCESS' && item.type_ncr === 'MANUAL'
                "
              >
                <b-button
                  @click="openModalAccountManual"
                  variant="primary"
                  size="md"
                  v-if="isSourceRequiredAccs"
                >
                  ADD ACCOUNT
                  <b-badge variant="danger" v-if="crAccounts.length > 0">
                    {{ totalAccountManualLead }}
                  </b-badge>
                </b-button>
              </b-col>
            </b-row>
            <!-- FILES -->
            <b-row v-if="attempt.result == 'SUCCESS'" class="mt-2">
              <template v-if="isAutomatic">
                <b-col cols="3">
                  <ValidationProvider
                    name="membership"
                    :rules="`required`"
                    v-slot="{ errors }"
                  >
                    <b-form-group label="Upload Cancelled Membership">
                      <b-form-file
                        v-model="attempt.files.membership"
                        accept=".pdf, .jpg, .jpeg, .png"
                        :class="{ 'border-danger rounded': errors[0] }"
                        :state="errors.length > 0 ? false : null"
                        @input="
                          validFile('membership', ['pdf', 'jpg', 'jpeg', 'png'])
                        "
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- PDF -->
                <b-col cols="3">
                  <ValidationProvider
                    name="pdf"
                    :rules="`required`"
                    v-slot="{ errors }"
                  >
                    <b-form-group label="Upload PDF">
                      <b-form-file
                        v-model="attempt.files.pdf"
                        accept=".pdf"
                        :class="{ 'border-danger rounded': errors[0] }"
                        :state="errors.length > 0 ? false : null"
                        @input="validFile('pdf', ['pdf'])"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="3">
                  <ValidationProvider
                    name="html"
                    :rules="`required`"
                    v-slot="{ errors }"
                  >
                    <b-form-group label="Upload HTML">
                      <b-form-file
                        v-model="attempt.files.html"
                        accept=".html, .htm"
                        :class="{ 'border-danger rounded': errors[0] }"
                        :state="errors.length > 0 ? false : null"
                        @input="validFile('html', ['html', 'htm'])"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- DATE -->
                <b-col cols="3">
                  <ValidationProvider
                    name="date"
                    :rules="`required`"
                    v-slot="{ errors }"
                  >
                    <b-form-group label="Date">
                      <b-form-datepicker
                        v-model="attempt.success.date"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </template>
              <template v-else>
                <b-col>
                  <b-row>
                    <!-- PDF -->
                    <b-col cols="8">
                      <ValidationProvider
                        name="pdf"
                        :rules="`required`"
                        v-slot="{ errors }"
                      >
                        <b-form-group label="Upload PDF">
                          <b-form-file
                            v-model="attempt.files.pdf"
                            accept=".pdf"
                            :class="{ 'border-danger rounded': errors[0] }"
                            :state="errors.length > 0 ? false : null"
                            @input="validFile('pdf', ['pdf'])"
                          />
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <!-- DATE -->
                    <b-col cols="4">
                      <ValidationProvider
                        name="date"
                        :rules="`required`"
                        v-slot="{ errors }"
                      >
                        <b-form-group label="Date">
                          <b-form-datepicker
                            v-model="attempt.success.date"
                            :date-format-options="{
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            }"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <!-- SCORES -->
                    <b-col v-for="score in arrScores" cols="4" :key="score.id">
                      <ValidationProvider
                        :name="score.name"
                        :rules="`required`"
                        v-slot="{ errors }"
                      >
                        <b-form-group :label="score.label">
                          <b-form-input
                            maxlength="3"
                            v-model="attempt.success[score.name]"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="isSourceRequiredAccs">
                  <b-table-simple sticky-header="20vh">
                    <b-thead>
                      <b-tr>
                        <b-th class="text-center col-6 v-middle">
                          Creditor Name
                        </b-th>
                        <b-th class="text-center v-middle">
                          <b-img
                            :src="
                              require('@/assets/images/icons/transunion.png')
                            "
                          />
                        </b-th>
                        <b-th class="text-center v-middle">
                          <b-img
                            :src="require('@/assets/images/icons/experian.png')"
                          />
                        </b-th>
                        <b-th class="text-center v-middle">
                          <b-img
                            :src="require('@/assets/images/icons/equifax.png')"
                          />
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <template v-if="accountsBureauFinal.length == 0">
                        <tr>
                          <td colspan="4" class="text-center">
                            There are no accounts registered yet
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="(item, index) in accountsBureauFinal"
                          :key="index"
                        >
                          <b-td>
                            {{ getNameCreditorAccounts(item) }}
                          </b-td>
                          <b-td
                            v-for="(item1, index1) in item"
                            :key="index1"
                            class="text-center"
                          >
                            <feather-icon
                              :icon="checkedBureau(item1).icon"
                              style="font-weight: bold"
                              :class="'text-' + checkedBureau(item1).color"
                              size="16"
                            />
                          </b-td>
                        </tr>
                      </template>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </template>
            </b-row>
            <!-- failed -->
            <b-row v-if="attempt.result == 'FAILED'" class="mt-2">
              <b-col :cols="isAutomatic ? 7 : 10">
                <ValidationProvider
                  name="motive"
                  :rules="`required`"
                  v-slot="{ errors }"
                >
                  <b-form-group>
                    <b-input-group>
                      <b-input-group-prepend class="w-20">
                        <b-input-group-text class="w-100">
                          <span>SELECT FAILED MOTIVE</span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-select
                        v-model="attempt.failed.motive"
                        :options="attempt.failed.motives"
                        :state="
                          errors.length > 0 && !reloadValidation ? false : null
                        "
                      />
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col :cols="2">
                <b-button @click="openModalFailedMotive()">
                  + ADD MOTIVE
                </b-button>
              </b-col>

              <b-col v-if="isAutomatic" cols="3">
                <b-form-group>
                  <b-form-file
                    v-model="attempt.files.membership"
                    accept=".pdf, .jpg, .jpeg, .png"
                    @input="
                      validFile('membership', ['pdf', 'jpg', 'jpeg', 'png'])
                    "
                    placeholder="Upload cancelled membership"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Add notes (optional)">
                  <b-form-textarea
                    v-model="attempt.failed.note"
                    rows="3"
                    max-rows="6"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </ValidationObserver>

      <template #modal-footer>
        <div class="d-flex justify-content-center">
          <b-button @click="save()" variant="success" v-if="item.status_id != 3"
            >Save</b-button
          >
        </div>
      </template>

      <modal-view-card
        v-if="showModalCard"
        :type="1"
        :card="dataCard"
        :isEditable="false"
        @onClose="showModalCard = false"
      />

      <modal-motive-failed
        v-if="showMotiveFailedModal"
        @close="closeModalFailedMotive"
      />
      <ModalAccountManual
        v-if="showModalAccountManual"
        :item="item"
        @closeModal="closeModalAccountManual"
        :bureaus="bureaus"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
// COMPONENTS
import ModalViewCard from "@/views/commons/components/credit-cards/ModalViewCard.vue";
import PersonalInformation from "@/views/commons/components/request-ncr/views/components/PersonalInformation.vue";
import ModalMotiveFailed from "@/views/commons/components/request-ncr/views/modals/ModalMotiveFailed.vue";

// SERVICES
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";
import ClientDashboardServices from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services.js";
import LeadService from "@/views/crm/services/lead/index.js";
import NcrProgramsService from "@/views/administration/views/ncr-programs/services/NcrPrograms.service.js";
import SettingsServiceSp from "@/views/specialists/views/settings/ncr-settings/service/settings.service.js";

// DATA
import CreditReportData from "@/views/administration/views/ncr-programs/components/data/creditReport.data.js";

// STORE
import { mapGetters, mapActions } from "vuex";
import ModalAccountManual from "@/views/commons/components/request-ncr/views/modals/ModalAccountManual.vue";

export default {
  components: {
    ModalViewCard,
    PersonalInformation,
    ModalMotiveFailed,
    ModalAccountManual,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isClient: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      manualSources: [1,3],
      bureaus: [],
      showModalAccountManual: false,
      ownControl: false,
      listCreditCard: CreditReportData.listCrediCard,
      listtypeCard: CreditReportData.listtypeCard,
      arrayColumnsTable: CreditReportData.arrayColumnsTable,
      attempt: {
        fileId: null,
        sources: [],
        source: {
          value: null,
          text: "Choose an option",
        },
        cards: [],
        disableCards: [],
        card: "",
        type_card: 1, // of client by default
        other_card: "",
        result: "",
        success: {
          date: moment().format("YYYY-MM-DD"),
          transunion: "",
          experian: "",
          equifax: "",
        },
        failed: {
          motives: [],
          motive: "",
          note: "",
        },
        files: {
          membership: null,
          pdf: null,
          html: null,
        },
      },
      arrScores: [
        { id: 1, name: "transunion", label: "TransUnion" },
        { id: 2, name: "experian", label: "Experian" },
        { id: 3, name: "equifax", label: "Equifax" },
      ],
      show: {
        document: false,
      },
      errorTable: "",
      showModalCard: false,
      dataCard: null,
      failedAttempt: false,
      isLastAttempt: false,
      reloadValidation: false,
      showMotiveFailedModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      getRefreshTable: "RequestNcrStore/getRefreshTable",
      totalAccountManualLead: "LeadNcrManualAccountStore/G_ACCOUNTS_TOTAL",
      accountsBureauFinal: "LeadNcrManualAccountStore/G_ACCOUNTS_FINAL",
      crAccounts: "LeadNcrManualAccountStore/G_ACCOUNTS",
    }),
    isSourceRequiredAccs() {
      return this.manualSources.includes(this.attempt.source.value)
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
      // mncr
      // return this.$route.matched[0].meta.module == 28
      //   ? 11
      //   : this.$route.matched[0].meta.module;
    },
    isAutomatic() {
      return this.item.type_ncr == "AUTOMATIC";
    },
    visibleFields() {
      return this.arrayColumnsTable.filter((field) => field.visible);
    },
    getSource() {
      let dataSource = this.attempt.sources.filter(
        (x) => x.value === this.attempt.source.value
      );
      return dataSource && dataSource.length > 0 ? dataSource[0].text : null;
    },
  },
  async created() {
    this.filterColumn("Actions", false); // hide actions column
    this.start();
    this.A_GET_ACCOUNT_MANUAL_LEAD({
      score_id: this.item.score_id,
    });
  },

  methods: {
    start() {
      this.startModal();
      this.startPromise();
      this.startCardMethods();
    },

    ...mapActions({
      CHANGE_REFRESH_TABLE: "RequestNcrStore/CHANGE_REFRESH_TABLE",
      A_REQUEST_NCR_COUNTERS: "RequestNcrStore/A_REQUEST_NCR_COUNTERS",
      A_GET_ORIGINAL_TARGET: "CrmCreditCardStore/A_GET_ORIGINAL_TARGET",
      A_REQUEST_WORKPLAN_COUNTERS:
        "RequestWorkPlanStore/A_REQUEST_WORKPLAN_COUNTERS",
      A_GET_ACCOUNT_MANUAL_LEAD: "LeadNcrManualAccountStore/A_LIST_ACCOUNTS",
    }),

    filterColumn(key, bool) {
      let field = this.arrayColumnsTable.find((field) => field.key == `${key}`);
      field.visible = bool;
    },

    startModal() {
      this.addPreloader();
      this.ownControl = true;
    },
    async startCardMethods() {
      this.getUsedCards(); // unable the used cards
      if (this.item.card_id) {
        this.attempt.card = this.item.card_id; // select the card used in the previous creation of request if exists
      } else {
        this.attempt.type_card = 2; // select the other type of cards
        // this.attempt.other_card = 1; // select the AMG cards
        await this.getAmgCards(); // get the cards
        this.attempt.card = this.attempt.cards[0]?.id; // pick the first AMG card
      }
    },
    async startPromise() {
      await Promise.all([
        this.getAvailableSources(),
        this.getFailedMotives(),
        this.isClient ? this.getClientCards() : this.getLeadCards(),
        this.validateFile(),
      ]);
    },

    addShowFullCardState(data) {
      this.attempt.cards = data.map((item) => {
        return {
          ...item,
          showFull: false,
          showSpinner: false,
          originalNumber: null,
        };
      });
    },

    creditCardToggleIcon(item) {
      return item.showFull ? "EyeOffIcon" : "EyeIcon";
    },

    async onToggleCreditCard(card) {
      try {
        if (!card.showFull) {
          card.showSpinner = true;
          const response = await this.A_GET_ORIGINAL_TARGET({
            id: card.id,
          });
          if (this.isResponseSuccess(response)) {
            card.showFull = true;
            card.originalNumber = response.data[0];
          } else {
            this.showToast(
              "warning",
              "top-right",
              "Warning!",
              "AlertTriangleIcon",
              `Something went wrong. ${response.message}`
            );
          }
        } else {
          card.showFull = false;
          card.originalNumber = null;
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      } finally {
        card.showSpinner = false;
      }
    },

    selectSource(param) {
      this.attempt.source.value = param.value;
      this.attempt.source.text = param.text;
    },
    checkExpiredCard(card) {
      return this.attempt.disableCards.includes(card.id);
    },
    validFile(prop, allowedExtensions) {
      const extension = this.attempt.files[prop]?.name.split(".").pop();
      if (!allowedExtensions.includes(extension) && !this.reloadValidation) {
        this.showInfoSwal(
          `Upload a file with one of the following extensions: ${allowedExtensions.join(
            ", "
          )}`,
          `Upload a valid file`
        );
        this.attempt.files[prop] = null;
      }
    },
    async save() {
      this.reloadValidation = false;
      const validation = await this.$refs.formNcrAttempt.validate();
      try {
        if (validation && !this.errorTable) {
          const response = await this.showConfirmSwal();
          if (!response.isConfirmed) return;
          if (
            this.attempt.result === "SUCCESS" &&
            this.item.type_ncr === "MANUAL"          
          ) {
            if (this.totalAccountManualLead === 0 && this.isSourceRequiredAccs) {
              // throw new Error("Please register at least one account", "Try again");
              this.showErrorSwal("Try again", "Please, register at least one account")
              return;
            }
          }

          this.addPreloader();

          const formData = new FormData();

          formData.append("id", this.item.id);
          formData.append("source", this.attempt.source.value);
          formData.append("card", this.attempt.card);
          formData.append("type_card", this.attempt.type_card);
          formData.append("result", this.attempt.result);
          formData.append("created_by", this.currentUser.user_id);
          formData.append(
            "account",
            this.item.client_account ? this.item.client_account : ""
          );
          formData.append(
            "program_id",
            this.item.program_id ? this.item.program_id : ""
          );
          formData.append(
            "amount",
            this.item.amount_manual
              ? this.item.amount_manual
              : this.item.amount_automatic
          );
          // info
          formData.append(
            "client_account_id",
            this.item.client_account_id ?? ""
          );
          formData.append("lead_id", this.item.lead_id ?? "");

          // success params
          formData.append(
            "cancelled_membership",
            this.attempt.files.membership ?? ""
          );
          formData.append("pdf", this.attempt.files.pdf ?? "");
          formData.append("html", this.attempt.files.html ?? "");
          formData.append("date", this.attempt.success.date);
          formData.append("transunion", this.attempt.success.transunion);
          formData.append("equifax", this.attempt.success.equifax);
          formData.append("experian", this.attempt.success.experian);
          formData.append("score_id", this.item.score_id ?? "");
          formData.append("ncr_letters_id", this.item.ncr_letters_id ?? "");
          formData.append("ncr_realtor_id", this.item.ncr_realtor_id ?? "");

          // failed params
          formData.append("motive_id", this.attempt.failed.motive);
          formData.append("note", this.attempt.failed.note);
          formData.append("type_ncr", this.item.type_ncr);
          formData.append("role_user_id", this.currentUser.role_id);
          formData.append("module_id_ncr", this.item.module_id);
          if (this.isSourceRequiredAccs) await this.accountsManual();
          const { success, score_id } = await NcrRequestService.saveAttempt(
            formData
          );
          if (!success) {
            this.failedAttempt = true;
            this.item.score_id = score_id;
            throw new Error(
              JSON.stringify({
                title:
                  "An error occurred while processing the html file, please choose a valid file or the correct source.",
                message: "COULD NOT PROCESS THE HTML FILE",
              })
            );
          }
          this.saveMe();
        } else {
          this.validOptionTable();
        }
      } catch (error) {
        if (!this.validateIfIsJson(error.message)) {
          this.showErrorSwal(error.message);
        } else {
          const dataError = JSON.parse(error.message);
          if (typeof dataError === "object") {
            this.showWarningSwal(dataError.message, dataError.title);
          } else {
            this.showErrorSwal(error.message);
          }
        }
      } finally {
        this.removePreloader();
      }
    },
    async accountsManual() {
      try {
        if (
          this.attempt.result === "SUCCESS" &&
          this.item.type_ncr === "MANUAL" &&
          !this.isClient
        ) {
          let cr = {
            date: "",
            score: [
              {
                score: this.attempt.success.transunion,
              },
              {
                score: this.attempt.success.experian,
              },
              {
                score: this.attempt.success.equifax,
              },
            ],
            personal: [],
            sumary: [],
            inquires: [],
            account: this.accountsBureauFinal,
            public: [],
          };
          await NcrRequestService.saveCrManualLead({
            cr,
            score_id: this.item.score_id,
          });
        }
      } catch (ex) {}
    },
    async saveMe() {
      this.item.number_of_attempts++;
      if (this.attempt.result == "SUCCESS" || this.isLastAttempt) {
        this.closeMe();
      } else {
        this.attempt.sources = [];
        this.attempt.failed.motives = [];
        await this.start();
        this.attempt.source.value = null;
        this.attempt.failed.motive = "";
        this.attempt.files.membership = null;
        this.attempt.failed.note = "";
        this.reloadValidation = true;
      }

      this.CHANGE_REFRESH_TABLE(true);
      this.A_REQUEST_NCR_COUNTERS(this.moduleId);

      if (this.isClient) {
        this.A_REQUEST_WORKPLAN_COUNTERS(29);
      }

      await this.showSuccessSwal(
        `The ${this.attempt.result.toLowerCase()} attempt was registered`,
        `${
          this.attempt.result == "FAILED"
            ? this.isLastAttempt
              ? "All attempts have been made"
              : "Try again"
            : "The request has been completed"
        }`
      );

      this.attempt.result = "";
    },

    async getClientCards() {
      try {
        let params = {
          account: this.item.client_account,
          program: this.item.program_id,
          session: this.currentUser.user_id,
          role: this.currentUser.role_id,
        };
        const response = await ClientDashboardServices.searchAccountNcr(params);
        const clientCards = JSON.parse(response.data[0].cards);
        const defaultCard = clientCards.find(
          (obj) => obj.id == this.item.card_id
        );
        // the modal will show only the card previously used in the creation of the ncr request

        this.addShowFullCardState(defaultCard ? [defaultCard] : []);
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong"
        );
      } finally {
        this.removePreloader();
      }
    },

    async getLeadCards() {
      try {
        const response = await LeadService.getLead(this.item.lead_id);
        if (response[0].cards) {
          this.addShowFullCardState(response[0].cards);
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went"
        );
      } finally {
        this.removePreloader();
      }
    },

    async getAmgCards() {
      try {
        const { data } = await NcrProgramsService.getCardsTpUsers();
        this.addShowFullCardState(data);
      } catch (error) {
        console.log(error);
      }
    },

    // async getCrmCards() {
    //   try {
    //     const { data } = await NcrProgramsService.getCardsTpClients();
    //     this.addShowFullCardState(data);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    getUsedCards() {
      const used_cards = JSON.parse(this.item.used_cards) || [];
      used_cards.forEach((element) => {
        this.attempt.disableCards.push(element.id);
      });
    },

    async getAvailableSources() {
      const data = await NcrRequestService.getAvailableSources({
        id: this.item.id,
        type_ncr: this.item.type_ncr,
      });

      data.forEach((item) => {
        this.attempt.sources.push({
          value: item.id,
          text: item.name,
          icon: item.icon ? `${this.baseUrl}${item.icon}` : "",
          disabled: Boolean(item.is_available),
        });
      });

      this.attempt.source.value = this.setFirstAttemptCondition();
      this.isLastAttempt = this.setLastAttemptCondition();
    },

    setFirstAttemptCondition() {
      if (!this.isAutomatic && this.item.number_of_attempts == 0) {
        return Number(this.item.type_manual);
      }

      return null;
    },

    setLastAttemptCondition() {
      let count = 0;
      for (const element of this.attempt.sources) {
        if (!element.disabled) count++;
      }

      return count == this.attempt.sources.length - 1;
    },

    async validateFile() {
      try {
        const { data } = await NcrRequestService.validateFileId({
          id: this.item.client_account_id ?? this.item.lead_id,
          isClient: this.isClient,
        });
        this.attempt.fileId = data.file;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong"
        );
      }
    },

    async getFailedMotives() {
      const { data } = await SettingsServiceSp.getNcrFailedMotives({});
      this.attempt.failed.motives = [];
      data.forEach((item) => {
        this.attempt.failed.motives.push({
          value: item.id,
          text: item.description,
        });
      });
    },

    viewDocument() {
      this.show.document = !this.show.document;
    },

    getDocument(value) {
      if (!this.show.document) {
        if (value != null && value != "") {
          return "xxx-xx-" + value.substr(-4);
        }
        return "xxx-xx-xxx";
      } else {
        return value;
      }
    },

    typeCard() {
      this.attempt.cards = [];
      this.attempt.card = "";
      if (this.attempt.type_card == 1) {
        this.isClient ? this.getClientCards() : this.getLeadCards();
      } else {
        this.getAmgCards();
      }
    },

    async validOptionTable() {
      const validation = await this.$refs.validateCreditCard?.validate();
      if (!validation?.valid) {
        this.errorTable = "is required"; // Stores the error
      } else {
        this.errorTable = ""; // Remove the set error
      }
    },

    async openModalCard(id) {
      try {
        const { data } = await NcrProgramsService.getCreditCard({ id: id });
        this.dataCard = data[0];
        this.showModalCard = true;
      } catch (error) {
        console.log(error);
      }
    },
    closeMe() {
      this.$emit("close");
    },
    async generateSignatureAuth() {
      try {
        const { data } = await NcrRequestService.getSignatureAuth({
          lead_id: this.item.lead_id,
          document_id: this.item.document_id,
        });
        const blob = new Blob([data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click();

        // Revoca la URL después de que el usuario interactúe o cierre la pestaña
        link.addEventListener("click", () => {
          URL.revokeObjectURL(url);
        });
      } catch (ex) {
        console.log(ex);
      }
    },
    downloadFileId(path) {
      window.open(path, "_blank");
    },
    openModalFailedMotive() {
      this.showMotiveFailedModal = true;
    },
    closeModalFailedMotive() {
      this.getFailedMotives();
      this.showMotiveFailedModal = false;
    },
    openModalAccountManual() {
      try {
        this.showModalAccountManual = true;
      } catch (ex) {}
    },
    closeModalAccountManual() {
      this.showModalAccountManual = false;
      if (!this.isClient) {
        this.A_GET_ACCOUNT_MANUAL_LEAD({
          score_id: this.item.score_id,
        });
      }
    },
    getNameCreditorAccounts(item) {
      const account = item.find((x) => x.a_name != "" && x.a_name != "-");
      return account ? account.a_name : "";
    },
    checkedBureau(item) {
      let valid = item.a_name != "" && item.a_name != "-";
      return {
        icon: valid ? "CheckIcon" : "XCircleIcon",
        color: valid ? "success" : "danger",
      };
    },
  },
  watch: {
    getRefreshTable(value) {
      if (value) {
        this.$refs.RequestNcrTable.refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
    "attempt.success": {
      handler(value) {
        this.bureaus = [value.transunion, value.experian, value.equifax];
      },
      deep: true,
    }
  },
  async beforeDestroy() {
    if (this.failedAttempt) {
      this.closeMe();
      await this.CHANGE_REFRESH_TABLE(true);
      await this.A_REQUEST_NCR_COUNTERS(this.moduleId);
    }
  },
};
</script>

<style lang="scss" scoped>
.border-yesornot {
  border-radius: 0 6px 6px 0;
}

.border-danger {
  border-radius: 6px;
}

.img-logo {
  width: 20px;
  height: 20px;
  border: 0;
  margin-right: 5px;
}
</style>

<style lang="scss">
.v-select-attempt {
  .vs__dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.radio-yesornot {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  label {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
</style>
