var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"flex flex-row mt-1 card-client-info",class:_vm.isDarkSkin ? 'dark' : 'light',attrs:{"no-body":""}},[_c('b-col',{staticClass:"ml-2 mr-0"},[_c('b-row',{staticClass:"mr-1",class:[
        _vm.isDarkSkin
          ? 'border-bottom border-2 '
          : 'border-bottom-secondary border-2 ' ]},[_c('b-col',[_c('b-row',[_c('h3',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.isClient ? "CLIENT" : "LEAD")+" INFORMATION ")])])],1)],1),_c('b-row',{staticClass:"pt-1",class:[
        _vm.isDarkSkin
          ? 'border-right border-2'
          : 'border-right-secondary border-2 ' ]},[_c('b-col',[_c('b-row',{staticClass:"pb-1"},[_c('span',{staticClass:"font-weight-bolder w-15"},[_vm._v(" NAME: ")]),_c('span',[_vm._v(_vm._s(_vm.item.fullname || "-"))])]),_c('b-row',[_c('b-col',{class:[
              _vm.isDarkSkin
                ? 'border-right border-2'
                : 'border-right-secondary border-2 ' ]},[_c('b-row',{staticClass:"mr-1 py-1",class:[
                _vm.isDarkSkin
                  ? 'border-top border-2 '
                  : 'border-top-secondary border-2 ' ]},[_c('span',{staticClass:"font-weight-bolder w-35"},[_vm._v(" ACCOUNT: ")]),_c('span',[_vm._v(" "+_vm._s(_vm.item.client_account || " Lead")+" ")])]),_c('b-row',{staticClass:"mr-1 py-1",class:[
                _vm.isDarkSkin
                  ? 'border-top border-bottom border-2'
                  : 'border-top-secondary border-bottom-secondary border-2' ]},[_c('span',{staticClass:"font-weight-bolder w-35"},[_vm._v(" DOB: ")]),_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(_vm.item.dob)))])])],1),_c('b-col',{staticClass:"ml-2"},[_c('b-row',{staticClass:"mr-1 py-1",class:[
                _vm.isDarkSkin
                  ? 'border-top border-2 '
                  : 'border-top-secondary border-2' ]},[_c('span',{staticClass:"font-weight-bolder w-25"},[_vm._v(" MOBILE: ")]),_c('span',[_vm._v(_vm._s(_vm.item.mobile || "-"))])]),_c('b-row',{staticClass:"mr-1 py-1",class:_vm.isDarkSkin
                  ? 'border-top border-bottom border-2'
                  : 'border-top-secondary border-bottom-secondary border-2'},[_c('span',{staticClass:"flex flex-row justify-content-center font-weight-bolder w-25"},[_vm._v(" "+_vm._s(_vm.item.document_name || "DOCUMENT")+": ")]),_c('span',[_vm._v(" "+_vm._s(_vm.getDocument || "-")+" "),(!_vm.showDocument)?_c('feather-icon',{staticClass:"ml-2 pointer text-success",attrs:{"icon":"EyeIcon"},on:{"click":_vm.viewDocument}}):_c('feather-icon',{staticClass:"ml-2 pointer text-danger",attrs:{"icon":"EyeOffIcon"},on:{"click":_vm.viewDocument}})],1)])],1)],1)],1)],1)],1),_c('b-col',{staticClass:"ml-2 mr-0"},[_c('b-row',{staticClass:"mr-1",class:_vm.isDarkSkin
          ? 'border-bottom border-2'
          : 'border-bottom-secondary border-2'},[_c('b-col',[_c('b-row',[_c('h3',{staticClass:"text-left"},[_vm._v("LOCATION")])])],1)],1),_c('b-row',{staticClass:"pt-1"},[_c('b-col',[_c('b-row',{staticClass:"pb-1"},[_c('span',{staticClass:"font-weight-bolder w-15"},[_vm._v("ADDRESS:")]),_c('span',[_vm._v(" "+_vm._s(_vm.item.street || "-")+" ")])]),_c('b-row',[_c('b-col',{class:_vm.isDarkSkin
                ? 'border-right border-2'
                : 'border-right-secondary border-2'},[_c('b-row',{staticClass:"mr-1 py-1",class:_vm.isDarkSkin
                  ? 'border-top border-2'
                  : 'border-top-secondary border-2'},[_c('span',{staticClass:"font-weight-bolder w-35"},[_vm._v("COUNTRY: ")]),_c('span',[_vm._v(_vm._s(_vm.item.country || "-"))])]),_c('b-row',{staticClass:"mr-1 py-1",class:[
                _vm.isDarkSkin
                  ? 'border-top border-bottom border-2 '
                  : 'border-top-secondary border-bottom-secondary border-2 ' ]},[_c('span',{staticClass:"font-weight-bolder w-35"},[_vm._v("CITY: ")]),_c('span',[_vm._v(_vm._s(_vm.item.city || "-"))])])],1),_c('b-col',{staticClass:"ml-2"},[_c('b-row',{staticClass:"mr-1 py-1",class:_vm.isDarkSkin
                  ? 'border-top border-2'
                  : 'border-top-secondary border-2 '},[_c('span',{staticClass:"font-weight-bolder w-25"},[_vm._v("STATE: ")]),_c('span',[_vm._v(_vm._s(_vm.item.states || "-"))])]),_c('b-row',{staticClass:"mr-1 py-1",class:[
                _vm.isDarkSkin
                  ? 'border-top border-bottom border-2'
                  : 'border-top-secondary border-bottom-secondary border-2' ]},[_c('span',{staticClass:"font-weight-bolder w-25"},[_vm._v("ZIP: ")]),_c('span',[_vm._v(_vm._s(_vm.item.zipcode || "-"))])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }