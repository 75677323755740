<template>
  <b-modal
    ref="modalMotiveFailes"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="ADD MOTIVE"
    size="md"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <ValidationObserver ref="form">
      <b-form-group label="Insert New Motive" label-for="input-1">
        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
          <b-input
            v-model="createdValue"
            placeholder="Add failed motive"
            autofocus
          />
          <template #append>
            <b-button
              variant="primary"
              @click="saveMotives()"
              :disabled="editing || createdValue == null || createdValue == ''"
              >ADD</b-button
            >
          </template>
        </b-input-group>
      </b-form-group>
      <div>
        <b-table striped hover small :fields="fields" :items="data">
          <template #cell(action)="data">
            <div v-if="editing && itemSelected == data.item.id">
              <feather-icon
                icon="SaveIcon"
                size="18"
                :class="'text-success'"
                @click="saveMotives(data.item.id)"
              /><feather-icon
                icon="XIcon"
                size="18"
                :class="'text-danger ml-1'"
                @click="cancelEdit()"
              />
            </div>
            <div v-else>
              <feather-icon
                class="clickable text-danger"
                icon="TrashIcon"
                size="18"
                @click="deleteItem(data.item.id)"
              />

              <feather-icon
                icon="EditIcon"
                size="18"
                :class="'text-info ml-1'"
                @click="edit(data.item)"
              />
            </div>
          </template>

          <template #cell(description)="data">
            <div v-if="itemSelected === data.item.id">
              <ValidationProvider
                v-slot="{ errors }"
                name="edit-motive"
                :rules="'required'"
              >
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <!-- <template #prepend>
                    <div class="input-group-text">
                      <b-icon icon="pencil-fill" class="h4 m-0"> </b-icon>
                    </div>
                  </template> -->
                  <b-input
                    placeholder="Add failed motive"
                    v-model="updatedValue"
                    :state="errors.length > 0 ? false : null"
                  >
                  </b-input>
                </b-input-group>
              </ValidationProvider>
            </div>
            <span v-else>
              <h5>{{ data.item.description }}</h5>
            </span>
          </template>
        </b-table>
      </div>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import SettingsServiceSp from "@/views/specialists/views/settings/ncr-settings/service/settings.service.js";
export default {
  props: {},
  data() {
    return {
      createdValue: null,
      itemSelected: null,
      editing: false,
      fields: [
        {
          key: "description",
          thStyle: {
            width: "50%",
          },
          label: "MOTIVE",
        },
        {
          key: "created_at",
          tdClass: "text-center",
          thClass: "text-center",
          formatter: (value) => {
            if (value) return moment(value).format("MM/DD/YYYY hh:mm A");
            return "-";
          },
        },
        {
          key: "action",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      data: [],
      id: null,
      updatedValue: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modalMotiveFailes");
  },
  created() {
    this.myProvider();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },

    async myProvider() {
      this.addPreloader();
      const formdata = {
        search: null,
        page: 1,
        perPage: 100,
      };
      try {
        let { data } = await SettingsServiceSp.getNcrFailedMotives(formdata);
        this.data = data;
        this.removePreloader();
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          error.message,
          "XIcon",
          "Something went wrong"
        );
      } finally {
        this.removePreloader();
      }
    },

    async saveMotives(id = null) {
      const validate = await this.$refs.form.validate();
      if (!validate) return;
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        let methodService;
        try {
          this.addPreloader();
          const formdata = {
            id: id,
            name: id ? this.updatedValue : this.createdValue,
            userId: this.currentUser.user_id,
          };

          if (!id) {
            methodService = "saveNcrFailedMotives";
          } else {
            methodService = "updateNcrFailedMotives";
          }
          const { data } = await SettingsServiceSp[methodService](formdata);
          if (data.success) {
            this.showToast(
              "success",
              "top-right",
              "Congratulations!",
              "CheckIcon",
              `Motive has been ${this.editing ? "updated" : "added"}`
            );

            this.editing = false;
            this.itemSelected = null;
            this.createdValue = null;

            this.myProvider();
          }
        } catch (error) {
          this.showToast(
            "error",
            "top-right",
            "Oops!",
            "XIcon",
            "Something went wrong"
          );
        } finally {
          this.removePreloader();
        }
      }
    },

    async deleteItem(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();

          const data = await SettingsServiceSp.deleteNcrFailedMotives({
            id: id,
            user_id: this.currentUser.user_id,
          });
          if (data.status === 200) {
            this.showSuccessSwal();
            this.myProvider();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    edit(item) {
      this.updatedValue = item.description;
      this.itemSelected = item.id;
      this.editing = true;
    },
    cancelEdit() {
      this.itemSelected = null;
      this.editing = false;
    },
  },
};
</script>

