<template>
  <b-card
    no-body
    class="flex flex-row mt-1 card-client-info"
    :class="isDarkSkin ? 'dark' : 'light'"
  >
    <!-- CLIENT INFORMATION -->
    <b-col class="ml-2 mr-0">
      <b-row
        class="mr-1"
        :class="[
          isDarkSkin
            ? 'border-bottom border-2 '
            : 'border-bottom-secondary border-2 ',
        ]"
      >
        <b-col>
          <!-- TITLE -->
          <b-row>
            <h3 class="text-left">
              {{ isClient ? "CLIENT" : "LEAD" }} INFORMATION
            </h3>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        class="pt-1"
        :class="[
          isDarkSkin
            ? 'border-right border-2'
            : 'border-right-secondary border-2 ',
        ]"
      >
        <b-col>
          <!-- NAME -->
          <b-row class="pb-1">
            <span class="font-weight-bolder w-15"> NAME: </span>
            <span>{{ item.fullname || "-" }}</span>
          </b-row>
          <b-row>
            <b-col
              :class="[
                isDarkSkin
                  ? 'border-right border-2'
                  : 'border-right-secondary border-2 ',
              ]"
            >
              <!-- ACCOUNT -->
              <b-row
                class="mr-1 py-1"
                :class="[
                  isDarkSkin
                    ? 'border-top border-2 '
                    : 'border-top-secondary border-2 ',
                ]"
              >
                <span class="font-weight-bolder w-35"> ACCOUNT: </span>
                <span>
                  {{ item.client_account || " Lead" }}
                </span>
              </b-row>
              <!-- DOB -->
              <b-row
                class="mr-1 py-1"
                :class="[
                  isDarkSkin
                    ? 'border-top border-bottom border-2'
                    : 'border-top-secondary border-bottom-secondary border-2',
                ]"
              >
                <span class="font-weight-bolder w-35"> DOB: </span>
                <span>{{ item.dob | myGlobal }}</span>
              </b-row>
            </b-col>
            <b-col class="ml-2">
              <!-- MOBILE -->
              <b-row
                class="mr-1 py-1"
                :class="[
                  isDarkSkin
                    ? 'border-top border-2 '
                    : 'border-top-secondary border-2',
                ]"
              >
                <span class="font-weight-bolder w-25"> MOBILE: </span>
                <span>{{ item.mobile || "-" }}</span>
              </b-row>
              <!-- DOCUMENT -->
              <b-row
                class="mr-1 py-1"
                :class="
                  isDarkSkin
                    ? 'border-top border-bottom border-2'
                    : 'border-top-secondary border-bottom-secondary border-2'
                "
              >
                <span
                  class="flex flex-row justify-content-center font-weight-bolder w-25"
                >
                  {{ item.document_name || "DOCUMENT" }}:
                </span>
                <span>
                  {{ getDocument || "-" }}
                  <feather-icon
                    v-if="!showDocument"
                    icon="EyeIcon"
                    class="ml-2 pointer text-success"
                    @click="viewDocument"
                  />
                  <feather-icon
                    v-else
                    icon="EyeOffIcon"
                    class="ml-2 pointer text-danger"
                    @click="viewDocument"
                  />
                </span>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <!-- LOCATION  -->
    <b-col class="ml-2 mr-0">
      <b-row
        class="mr-1"
        :class="
          isDarkSkin
            ? 'border-bottom border-2'
            : 'border-bottom-secondary border-2'
        "
      >
        <b-col>
          <!-- TITLE -->
          <b-row>
            <h3 class="text-left">LOCATION</h3>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <b-col>
          <!-- ADDRESS -->
          <b-row class="pb-1">
            <span class="font-weight-bolder w-15">ADDRESS:</span>
            <span> {{ item.street || "-" }} </span>
          </b-row>
          <b-row>
            <b-col
              :class="
                isDarkSkin
                  ? 'border-right border-2'
                  : 'border-right-secondary border-2'
              "
            >
              <!-- COUNTRY -->
              <b-row
                class="mr-1 py-1"
                :class="
                  isDarkSkin
                    ? 'border-top border-2'
                    : 'border-top-secondary border-2'
                "
              >
                <span class="font-weight-bolder w-35">COUNTRY: </span>
                <span>{{ item.country || "-" }}</span>
              </b-row>
              <!-- CITY -->
              <b-row
                class="mr-1 py-1"
                :class="[
                  isDarkSkin
                    ? 'border-top border-bottom border-2 '
                    : 'border-top-secondary border-bottom-secondary border-2 ',
                ]"
              >
                <span class="font-weight-bolder w-35">CITY: </span>
                <span>{{ item.city || "-" }}</span>
              </b-row>
            </b-col>
            <b-col class="ml-2">
              <!-- STATE -->
              <b-row
                class="mr-1 py-1"
                :class="
                  isDarkSkin
                    ? 'border-top border-2'
                    : 'border-top-secondary border-2 '
                "
              >
                <span class="font-weight-bolder w-25">STATE: </span>
                <span>{{ item.states || "-" }}</span>
              </b-row>
              <!-- ZIP -->
              <b-row
                class="mr-1 py-1"
                :class="[
                  isDarkSkin
                    ? 'border-top border-bottom border-2'
                    : 'border-top-secondary border-bottom-secondary border-2',
                ]"
              >
                <span class="font-weight-bolder w-25">ZIP: </span>
                <span>{{ item.zipcode || "-" }}</span>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    isClient: {
      type: Boolean,
      required: true,
    },
    showDocument: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    viewDocument() {
      this.$emit("viewDocument");
    },
  },
  computed: {
    cardVariant() {
      return this.isDarkSkin ? "dark" : "light";
    },
    myDocument() {
      return this.item.document;
    },
    getDocument() {
      const hashedDoc = this.myDocument
        ? `XXX-XX-${this.myDocument.substr(-4)}`
        : "XXX-XX-XXX";

      return this.showDocument ? this.myDocument : hashedDoc;
    },
  },
};
</script>
<style lang="scss" scoped>
.card-client-info {
  border-radius: 20px;
  padding: 1rem 0;
  background: linear-gradient(90deg, #e1e0e0 0%, #dddddd 100%);
  &.dark {
    background: linear-gradient(90deg, #262626 0%, #333334 100%);
  }
}
h3 {
  font-weight: bold;
}
span {
  margin: 0;
}

.w-15 {
  width: 15%;
}

.w-25 {
  width: 25%;
}

.w-35 {
  width: 35%;
}
</style>
