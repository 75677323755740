<template>
  <b-container
    class="d-flex"
    :style="
      isBigWindow
        ? statusApp == 1
          ? 'padding-right:150px'
          : 'padding-right:50px'
        : 'padding-right: 30px;padding-left: 30px;'
    "
    :class="isBigWindow ? 'justify-content-end' : 'justify-content-center'"
  >
    <b-col :cols="12" lg="4" xl="2" class="m-0">
      <b-row
        v-if="valid != 2"
        style="min-width: 200px"
        class="rounded-pill bg-purple justify-content-center"
        :style="isBigWindow ? 'width: 100px !important; ' : ''"
      >
        <h3>
          <span> Sub Total: </span> $ {{ this.subtotal | currency }}
        </h3> </b-row
      ><br />
      <b-row
        v-if="valid != 1"
        style="min-width: 200px"
        class="rounded-pill bg-purple justify-content-center"
        :style="isBigWindow ? 'width: 200px;' : ''"
      >
        <h3><span> Total: </span>$ {{ this.total | currency }}</h3>
      </b-row>
    </b-col>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    total: { type: Number, default: 0.0 },
    subtotal: { type: Number, default: 0.0 },
    valid: { type: Number, default: 3 },
  },
  computed: {
    statusApp() {
      return this.$route.meta.statusApp;
    },
    ...mapGetters({
      isBigWindow: "app/bigWindow",
    }),
  },
};
</script>
<style scoped>
h3 {
  margin: 0;
  font-size: 1.2rem;
  text-align: center !important;
  padding-bottom: 5px;
  padding-top: 5px;
  border: none;
  color: #f2f2f2;
}
.bg-purple {
  background-color: #8f5fe8;
}
</style>
