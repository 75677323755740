import { extend } from "vee-validate";
// Helper function to calculate days in a month (considering leap years)
const getValidDaysInMonth = (year, month) => {
    return [
        31,
        (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31,
    ][month - 1];
};
export const validateDateFormat = (value) => {
    // Regular expression for mm/dd/yyyy format
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;

    // Check if value matches the format
    if (!regex.test(value)) return false;

    // Split the date string into components
    const [month, day, year] = value.split('/');

    // Validate month (1-12)
    if (month < 1 || month > 12) return false;

    // Validate day (1-31, considering leap years)
    const daysInMonth = getValidDaysInMonth(year, month);
    if (day < 1 || day > daysInMonth) return false;

    return true;
}
extend('date_format', {
    validate(value) {
        return validateDateFormat(value);
    },
    message: 'Invalid date. Please enter a valid mm/dd/yyyy date.'
});

export const validateNotFutureDate = (value) => {
    let today = new Date();
    let inputDate = new Date(value);
    return inputDate > today ? false : true;
}

extend('validate_not_future_date', {
    validate(value) {
        return validateNotFutureDate(value);
    },
    message: 'Invalid date. Please enter a valid date.'
})