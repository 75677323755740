<template>
  <b-modal
    size="xlg"
    @hidden="closeModal"
    no-close-on-backdrop
    ref="modalAccountManual"
    title="ADD MANUAL ACCOUNTS"
    hide-footer
  >
    <template #modal-header>
      <h5 :class="isDarkSkin ? '' : 'text-light'" class="m-0">
        ADD MANUAL ACCOUNTS
      </h5>
      <!--BUTTON CLOSE MODAL-->

      <b-button
        variant="white"
        class="bg-white position-absolute"
        style="right: -10px; top: -10px; z-index: 20"
        size="sm"
        @click="closeModal"
      >
        <feather-icon icon="XIcon" size="20" />
      </b-button>
    </template>
    <b-container>
      <AddAccountManual
        :crAccountsStore="crAccountsStore"
        :statusDone="statusDone"
        @doneAccounts="doneAccounts"
        @saveAccounts="saveAccounts"
        @deleteAccounts="deleteAccounts"
      />
    </b-container>
  </b-modal>
</template>
<script>
import AddAccountManual from "@/views/commons/components/request-ncr/views/modals/AddAccountsManual/AddAccountManual.vue";
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "ModalAccountManual",
  props: {
    item: {
      type: Object,
      required: true,
    },
    bureaus: {
      type: Array,
      required: true,
    },
  },
  components: {
    AddAccountManual,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      crAccountsStore: "LeadNcrManualAccountStore/G_ACCOUNTS",
      statusDone: "LeadNcrManualAccountStore/G_DONE_ACCOUNTS",
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      A_ACCOUNTS_ADD: "LeadNcrManualAccountStore/A_ACCOUNTS_ADD",
    }),
    async closeModal() {
      try {
        this.$emit("closeModal");
      } catch (ex) {}
    },
    async deleteAccounts(cr) {
      try {
        await NcrRequestService.saveCrManualLead({
          cr,
          score_id: this.item.score_id,
        });
        this.showGenericToast({
          text: "Deleted accounts",
          title: "Delete",
        });
      } catch (ex) {}
    },
    async doneAccounts(cr) {
      try {
        await NcrRequestService.saveCrManualLead({
          cr,
          score_id: this.item.score_id,
        });
        this.A_ACCOUNTS_ADD(cr.account);
        this.closeModal();
        this.removePreloader();
        this.showGenericToast({
          text: "Action successfully completed",
          title: "Done",
        });
      } catch (ex) {}
    },
    async saveAccounts(cr) {
      try {
        await NcrRequestService.saveCrManualLead({
          cr,
          score_id: this.item.score_id,
        });
        this.A_ACCOUNTS_ADD(cr.account);
        this.removePreloader();
        this.showGenericToast({
          text: "Successfully saved",
          title: "Save Accounts",
        });
      } catch (ex) {}
    },
  },
  mounted() {
    this.toggleModal("modalAccountManual");
  },
};
</script>
