var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.field.type === 'money')?[_c('validation-provider',{attrs:{"rules":((_vm.item[_vm.field.key].required ? 'required|' : '') + "noHtmlTags")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control form-control-sm",class:errors[0] ? 'is-invalid' : 'valid',attrs:{"placeholder":_vm.field.placeholder,"disabled":_vm.disabled},model:{value:(_vm.item[_vm.field.key].value),callback:function ($$v) {_vm.$set(_vm.item[_vm.field.key], "value", $$v)},expression:"item[field.key].value"}},'money',{
          decimal: '.',
          thousands: ',',
          prefix: '$ ',
          precision: 2,
        },false))]}}],null,false,59248183)})]:(_vm.field.type == 'date')?[_c('validation-provider',{attrs:{"rules":((_vm.item[_vm.field.key].required ? 'required|' : '') + "noHtmlTags|validate_not_future_date")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('kendo-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"form-control",class:[
          'w-100 rounded bg-transparent k-picker-custom',
          { 'text-white': _vm.isDarkSkin },
          errors[0] ? 'is-invalid' : 'valid'
        ],style:(_vm.isDarkSkin ? 'border-color: rgba(255, 255, 255, 0.4)' : ''),attrs:{"format":'MM/dd/yyyy',"disabled":_vm.disabled,"max":new Date(),"placeholder":"MM/dd/yyyy"},model:{value:(_vm.item[_vm.field.key].value),callback:function ($$v) {_vm.$set(_vm.item[_vm.field.key], "value", $$v)},expression:"item[field.key].value"}})]}}])})]:(_vm.field.type === 'number')?[_c('validation-provider',{attrs:{"rules":((_vm.item[_vm.field.key].required ? 'required|' : '') + "is_numeric|noHtmlTags")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'is-invalid' : 'valid',attrs:{"size":"sm","placeholder":_vm.field.placeholder,"disabled":_vm.disabled},model:{value:(_vm.item[_vm.field.key].value),callback:function ($$v) {_vm.$set(_vm.item[_vm.field.key], "value", $$v)},expression:"item[field.key].value"}})]}}])})]:(_vm.field.input === 'select')?[_c('validation-provider',{attrs:{"rules":((_vm.item[_vm.field.key].required ? 'required|' : '') + "noHtmlTags")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_vm._t(_vm.field.key,null,{"item":_vm.item,"errors":errors,"field":_vm.field})]}}],null,true)})]:[_c('validation-provider',{attrs:{"rules":((_vm.item[_vm.field.key].required ? 'required|' : '') + "noHtmlTags")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'is-invalid' : 'valid',attrs:{"disabled":_vm.disabled,"size":"sm","placeholder":_vm.field.placeholder},model:{value:(_vm.item[_vm.field.key].value),callback:function ($$v) {_vm.$set(_vm.item[_vm.field.key], "value", $$v)},expression:"item[field.key].value"}})]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }