<template>
  <div>
    <template v-if="field.type === 'money'">
      <validation-provider
        v-slot="{ errors }"
        :rules="`${item[field.key].required ? 'required|' : ''}noHtmlTags`"
      >
        <money
          v-model="item[field.key].value"
          v-bind="{
            decimal: '.',
            thousands: ',',
            prefix: '$ ',
            precision: 2,
          }"
          class="form-control form-control-sm"
          :placeholder="field.placeholder"
          :class="errors[0] ? 'is-invalid' : 'valid'"
          :disabled="disabled"
        />
      </validation-provider>
    </template>
    <template v-else-if="field.type == 'date'">
      <validation-provider
        v-slot="{ errors }"
        :rules="`${
          item[field.key].required ? 'required|' : ''
        }noHtmlTags|validate_not_future_date`"
      >
        <kendo-datepicker
          v-model="item[field.key].value"
          v-mask="'##/##/####'"
          :style="isDarkSkin ? 'border-color: rgba(255, 255, 255, 0.4)' : ''"
          :class="[
            'w-100 rounded bg-transparent k-picker-custom',
            { 'text-white': isDarkSkin },
            errors[0] ? 'is-invalid' : 'valid'
          ]"
          :format="'MM/dd/yyyy'"
          class="form-control"
          :disabled="disabled"
          :max="new Date()"
          placeholder="MM/dd/yyyy"
        />
      </validation-provider>
    </template>
    <template v-else-if="field.type === 'number'">
      <validation-provider
        v-slot="{ errors }"
        :rules="`${
          item[field.key].required ? 'required|' : ''
        }is_numeric|noHtmlTags`"
      >
        <b-form-input
          v-model="item[field.key].value"
          size="sm"
          :placeholder="field.placeholder"
          :disabled="disabled"
          :class="errors[0] ? 'is-invalid' : 'valid'"
        ></b-form-input>
      </validation-provider>
    </template>
    <template v-else-if="field.input === 'select'">
      <validation-provider
        v-slot="{ errors }"
        :rules="`${item[field.key].required ? 'required|' : ''}noHtmlTags`"
      >
        <slot :name="field.key" :item="item" :errors="errors" :field="field" />
      </validation-provider>
    </template>
    <template v-else>
      <validation-provider
        v-slot="{ errors }"
        :rules="`${item[field.key].required ? 'required|' : ''}noHtmlTags`"
      >
        <b-form-input
          :disabled="disabled"
          v-model="item[field.key].value"
          size="sm"
          :placeholder="field.placeholder"
          :class="errors[0] ? 'is-invalid' : 'valid'"
        ></b-form-input>
      </validation-provider>
      <!-- item[field.key].required -->
    </template>
  </div>
</template>
<script>
import "./rules/lead-manual.rules";
export default {
  name: "InputItemAccount",
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    disabled:{
      type:Boolean,
      required:true
    }
  },
  methods: {
    setRules(field) {
      if (field.required) {
        return "required";
      } else {
        return "";
      }
    },
  },
};
</script>