<template>
  <b-modal
    ref="modal_view_legends_ncr"
    size="md"
    title="STATE LEGENDS"
    header-bg-variant="info"
    header-text-variant="dark"
    body-bg-variant="ligth"
    body-text-variant="dark"
    footer-bg-variant="ligth"
    footer-text-variant="dark"
    no-close-on-backdrop
    class="border-0 border-white"
    scrollable
    @hidden="closeModal"
    hide-footer
  >
    <template #default>
      <b-list-group variant="primary">
        <h5>STATUS IN {{ title }}:</h5>
        <b-list-group-item
          v-for="item in ArrayFilteredBySubTab(subTabName)"
          :key="item.id"
        >
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
              <b-badge
                style="width: 120px"
                :style="
                  item.id == 1
                    ? 'background: #fad0141f; color:#fad014 !important'
                    : ''
                "
                :variant="badgeColor(item.id)"
              >
                {{ item.name }}
              </b-badge>
            </h5>
          </div>

          <p class="mb-1">
            {{ item.description }}
          </p>
        </b-list-group-item>
      </b-list-group>
    </template>
  </b-modal>
</template>
<script>
export default {
  props: {
    subTabName: {
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      legendArray: [
        {
          id: 1,
          name: "PENDING",
          description: "NCR REQUEST PENDING REVIEW",
        },
        // {
        //   id: 2,
        //   name: "IN PROCESS",
        //   description: "NCR REQUEST UNDER REVIEW AND MAKING ATTEMPTS",
        // },
        {
          id: 3,
          name: "COMPLETED",
          description: "NCR REQUEST SUCCESSFULLY COMPLETED",
        },
        {
          id: 4,
          name: "FAILED",
          description: "NCR REQUEST FAILED",
        },
        {
          id: 5,
          name: "ANNULLED",
          description: "NCR REQUEST IS ANNULED",
        },
        {
          id: 6,
          name: "FAILED ATTEMPT",
          description: "NCR REQUEST ATTEMPT FAILED",
        },
      ],
    };
  },

  created() {},

  mounted() {
    this.toggleModal("modal_view_legends_ncr");
  },

  computed: {
    title() {
      if (this.subTabName === "pending") {
        return "PENDING";
      } else if (this.subTabName === "finished") {
        return "FINISHED";
      } else {
        return "ANNULLED";
      }
    },
  },
  methods: {
    badgeColor(badge) {
      let badgeClass = {
        1: "light-secondary",
        2: "light-info",
        3: "light-success",
        4: "light-danger",
        5: "light-secondary",
        6: "light-warning",
      };
      return badgeClass[badge];
    },

    ArrayFilteredBySubTab(subTab) {
      if (subTab === "pending") {
        return this.legendArray.filter(
          (item) => item.id !== 3 && item.id !== 4 && item.id !== 5
        );
      } else if (subTab === "finished") {
        return this.legendArray.filter(
          (item) => item.id === 3 || item.id === 4
        );
      } else {
        return this.legendArray.filter((item) => item.id === 5);
      }
    },

    closeModal() {
      this.$emit("hidden");
    },
  },
};
</script>
