export default [
  {
    key: "client",
    label: "Client",
    class: "text-left",
    visible: false,
  },
  {
    key: "lead",
    label: "Lead",
    class: "text-left",
    visible: false,
  },
  {
    key: "type",
    label: "type",
    class: "text-center",
    visible: true,
  },
  {
    key: "departament",
    label: "module",
    class: "text-center",
    visible: false,
  },
  {
    key: "status",
    label: "Status",
    class: "text-center",
    visible: true,
  },
  {
    key: "description",
    label: "Description",
    class: "text-center",
    thClass: "px-1",
    visible: true,
  },
  {
    key: "charge",
    label: "Charge",
    class: "text-center",
    visible: true,
  },
  {
    key: "card",
    label: "card",
    class: "text-center",
    visible: true,
  },
  {
    key: "score",
    label: "Score",
    class: "text-center",
    thClass: "px-1",
    tdClass: "px-0",
    visible: true,
  },
  {
    key: "attempts",
    label: "Attempts",
    class: "text-center",
    thClass: "px-0",
    visible: true,
  },
  {
    key: "analysis",
    label: "analysis",
    class: "text-center",
    thClass: "px-0",
    visible: false,
  },
  {
    key: "cr",
    label: "cr",
    class: "text-center",
    thClass: "px-1",
    visible: false,
  },
  {
    key: "requested_by",
    label: "Requested by",
    class: "text-center",
    visible: true,
  },
  {
    key: "obtained_by",
    label: "Obtained by",
    class: "text-center",
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    class: "text-center",
    thClass: "px-1",
    visible: true,
  },
  {
    key: "actions",
    label: "Actions",
    class: "text-center",
    visible: true,
  },
];
