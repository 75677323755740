<template>
  <b-card no-body class="mb-0 border-0 p-0">
    <filter-slot
      :filter="filterSlot.filters"
      :filterSearch="filterSlot.filterSearch"
      :paginate="filterSlot.paginate"
      :totalRows="filterSlot.totalRows"
      :startRow="filterSlot.startRow"
      :endRow="filterSlot.endRow"
      :toPage="filterSlot.toPage"
      @reload="reloadTable"
      @reset-all-filters="resetAllFilters"
    >
      <template #buttons>
        <div>
          <b-button
            :class="{
              'item-magenta': typeReport === 1,
              'bg-light-secondary': typeReport !== 1,
              'cursor-not-allowed': disabledBtn,
            }"
            class="ml-1"
            v-b-tooltip.hover.top="
              typeReport == 1 ? 'Cancel Filter' : 'Filter by Automatic'
            "
            @click="selectTypeReport(1)"
            :disabled="disabledBtn"
          >
            Automatic
          </b-button>

          <b-button
            :class="{
              'item-purple': typeReport === 2,
              'bg-light-secondary': typeReport !== 2,
              'cursor-not-allowed': disabledBtn,
            }"
            class="ml-1"
            v-b-tooltip.hover.top="
              typeReport == 2 ? 'Cancel Filter' : 'Filter by Manual'
            "
            @click="selectTypeReport(2)"
            :disabled="disabledBtn"
          >
            Manual
          </b-button>
          <feather-icon
            icon="InfoIcon"
            size="18"
            @click="openModalStateLegend"
            v-b-tooltip.hover.right="'View State Legends'"
            class="align-middle text-warning ml-2 cursor-pointer"
          />
        </div>
      </template>
      <template #table>
        <b-table
          slot="table"
          no-provider-filtering
          ref="RequestNcrTable"
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive
          show-empty
          :busy.sync="isBusy"
          :per-page="filterSlot.paginate.perPage"
          :current-page="filterSlot.paginate.currentPage"
          id="table-request-ncr"
          :sticky-header="'70vh'"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client)="data">
            <span class="d-flex align-items-center" style="gap: 5px">
              <div v-if="redirectValidation(data.item)">
                <router-link
                  target="_blank"
                  :to="{
                    name: clientDashboardRouteName,
                    params: {
                      idClient: `${data.item.client_account_id}`,
                    },
                  }"
                >
                  {{ data.item.fullname }}
                </router-link>
              </div>
              <div v-else class="text-primary">
                {{ data.item.fullname }}
              </div>
            </span>
            <div>
              <status-account
                :account="{ ...data.item, status: data.item.status_account }"
              ></status-account>
            </div>
            <span class="d-block">
              {{ data.item.client_account }}
            </span>
            <b-badge
              variant="light-warning"
              style="margin-left: 18px"
              v-if="data.item.update_id"
            >
              FROM UPDATE
            </b-badge>
          </template>

          <template #cell(lead)="data">
            <span
              class="d-flex align-items-center"
              style="gap: 5px; margin-top: 10px"
            >
              <div v-if="redirectValidation(data.item)">
                <router-link
                  class="text-warning"
                  style="font-weight: bolder"
                  target="_blank"
                  :to="{
                    name: leadDashboardRouteName,
                    params: {
                      id: `${data.item.lead_id}`,
                    },
                  }"
                >
                  {{
                    data.item.fullname ? data.item.fullname : data.item.nickname
                  }}
                </router-link>
              </div>
              <div v-else class="text-primary">
                {{ data.item.fullname }}
              </div>
            </span>
          </template>

          <template #cell(description)="data">
            <span
              v-if="data.item.obs"
              class="cursor-pointer text-primary"
              @click="openFullTextModal(data.item)"
            >
              <feather-icon
                icon="BookOpenIcon"
                size="18"
                v-b-tooltip.hover.right="'View Description'"
                class="align-middle text-primary"
              />
            </span>
            <span v-else> - </span>
          </template>

          <template #cell(type)="data">
            <div class="text-info text-center">
              <b-badge
                style="width: 90px"
                :class="
                  data.item.type_ncr == 'AUTOMATIC'
                    ? 'item-magenta'
                    : 'item-purple'
                "
              >
                <span>
                  {{ data.item.type_ncr }}
                </span>
              </b-badge>
            </div>
          </template>

          <template #cell(departament)="data">
            <div class="text-info text-center">
              {{ nameModule(data.item.module_name) }}
            </div>
          </template>

          <template #cell(status)="data">
            <div
              class="icon-container"
              :class="
                data.item.status_id == 2 &&
                !isFinancialApproval &&
                'cursor-info'
              "
              v-b-tooltip.hover.top="
                data.item.status_id == 2 &&
                !isFinancialApproval &&
                `This request failed ${data.item.number_of_attempts} times, see more details on tracking`
              "
            >
              <b-badge
                style="width: 110px"
                :style="
                  data.item.status_id == 1 &&
                  'background: #fad0141f; color:#fad014 !important'
                "
                :variant="badgeColor(data.item.status_id)"
                >{{
                  data.item.status == "IN PROCESS"
                    ? "FAILED ATTEMPT"
                    : data.item.status
                }}</b-badge
              >
              <span
                v-if="data.item.status_id == 2 && !isFinancialApproval"
                class="custom-badge border-1 rounded-circle border-secondary text-secondary"
                >{{ data.item.number_of_attempts }}</span
              >
            </div>
          </template>

          <template #cell(card)="data">
            <div>
              {{ data.item.cardnumber || "-" }}
            </div>
          </template>

          <template #cell(charge)="data">
            <div>
              <b-badge
                :variant="
                  badgeVariant(
                    data.item.amount_automatic,
                    data.item.amount_manual
                  )
                "
              >
                $
                {{
                  (data.item.amount_manual || data.item.amount_automatic)
                    | currency
                }}
              </b-badge>
            </div>
          </template>

          <template #cell(requested_by)="data">
            <div>
              {{ data.item.created_by }}
              <br />
              {{ data.item.created_at | myGlobalDay }}
            </div>
          </template>

          <template #cell(obtained_by)="data">
            <div>
              {{ data.item.obtained_by_name }}
              <br />
              {{ data.item.obtained_at | myGlobalDay }}
            </div>
          </template>

          <template #cell(score)="data">
            <b-dropdown variant="link" no-caret>
              <template #button-content>
                <feather-icon
                  icon="SlidersIcon"
                  size="16"
                  v-b-tooltip.hover.right="'Score'"
                  class="align-middle text-info"
                />
              </template>

              <!-- Score TU -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'TU'"
                v-b-modal.modal-primary
              >
                <span class="align-middle ml-50"
                  >TU : {{ data.item.transunion || "-" }}</span
                >
              </b-dropdown-item>

              <!-- Score EX -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'EX'"
                v-b-modal.modal-primary
              >
                <span class="align-middle ml-50"
                  >EX : {{ data.item.experian || "-" }}</span
                >
              </b-dropdown-item>

              <!-- Score EQ -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'EQ'"
                v-b-modal.modal-primary
              >
                <span class="align-middle ml-50"
                  >EQ : {{ data.item.equifax || "-" }}</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #cell(attempts)="data">
            <div
              class="icon-container cursor-pointer text-primary"
              @click="openAttemptsModal(data.item)"
              v-b-tooltip.hover.bottom="
                data.item.status === 'TO ANALYZE' ? 'TO ANALYZE' : 'New Attempt'
              "
            >
              <div v-if="data.item.status_id != 3">
                <feather-icon
                  icon="InboxIcon"
                  size="18"
                  :class="
                    data.item.status === 'TO ANALYZE' ? 'text-success' : ''
                  "
                />
                <span
                  class="custom-badge border-1 rounded-circle border-secondary text-secondary"
                  >{{ data.item.number_of_attempts }}</span
                >
              </div>

              <img
                v-else
                :src="
                  data.item.plataform_ico
                    ? baseUrl + data.item.plataform_ico
                    : ''
                "
                :alt="data.item.plataform_name"
              />
            </div>
          </template>

          <template #cell(analysis)="data">
            <template v-if="isAnalyzed(data.item)">
              <feather-icon
                v-if="data.item.is_analyzed === 0 && moduleId !== 29"
                icon="SearchIcon"
                class="text-warning"
                size="18"
                v-b-tooltip.hover.v-warning="'PENDING ANALYSIS CR'"
              />
              <template v-else>
                <feather-icon
                  :id="`analysis_${data.index}`"
                  icon="SearchIcon"
                  :class="colorStatusAnalysis(data.item)"
                  size="18"
                  @click="generateAnalysisNCR(data.item)"
                />
                <b-tooltip
                  :variant="data.item.is_analyzed === 0 ? 'warning' : 'success'"
                  :target="`analysis_${data.index}`"
                >
                  {{
                    data.item.is_analyzed === 0
                      ? moduleId == 29
                        ? "GENERATE ANALYSIS CR"
                        : "PENDING ANALYSIS CR"
                      : "CR ANALYSIS GENERATED"
                  }}
                </b-tooltip>
              </template>
            </template>
            <template v-else> - </template>
          </template>

          <template #cell(tracking)="data">
            <div>
              <feather-icon
                class="text-info cursor-pointer"
                v-b-tooltip.hover.right="'Tracking'"
                @click="openModalTracking(data.item)"
                size="16"
                icon="ListIcon"
              />
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex align-items-center justify-content-around">
              <!-- ANNULLED -->
              <feather-icon
                size="16"
                icon="XSquareIcon"
                v-if="
                  data.item.status_id != 5 &&
                  isFinancialApproval &&
                  isPendingSubTab
                "
                v-b-tooltip.hover.top="'Annull NCR Request'"
                @click="AnnulledNcrRequest(data.item)"
                class="text-danger clickable"
              />
              <!-- PAYMENT -->

              <div
                v-if="
                  data.item.type_ncr == 'AUTOMATIC' &&
                  data.item.status_id == 3 &&
                  data.item.module_id == moduleId &&
                  Number(data.item.amount_automatic) > 0
                "
                @click="collectPayment(data.item)"
              >
                <amg-icon
                  v-if="data.item.is_collected == 2"
                  class="cursor-deafult mt-0 mr-0 bigger text-success"
                  icon="MoneyBagIcon"
                  size="17"
                  v-b-tooltip.hover.top="'✓ Paid'"
                />
                <tabler-icon
                  icon="CoinIcon"
                  size="18"
                  v-else
                  v-b-tooltip.hover.top="'Pay'"
                  class="cursor-pointer oscillate"
                  style="color: #f8fc00"
                />
              </div>

              <!-- PDF -->
              <amg-icon
                v-if="data.item.status_id == 3"
                v-b-tooltip.hover.top="'View PDF'"
                @click="viewPdfNcr(data.item)"
                class="cursor-pointer mt-0 mr-0 bigger text-danger"
                icon="FilePdfIcon"
                style="height: 15px; width: 15px"
              />

              <!-- MANUAL REQUEST AFTER ALL ATTEMPTS -->
              <feather-icon
                class="text-info clickable"
                size="16"
                v-if="
                  isFinishedSubTab &&
                  data.item.type_ncr == 'AUTOMATIC' &&
                  data.item.status_id == 4 &&
                  data.item.module_id == moduleId &&
                  data.item.child_id == null
                "
                @click="openRequestNcrModal(data.item)"
                v-b-tooltip.hover.top="'New Manual Request'"
                icon="FilePlusIcon"
              />
            </div>
          </template>
        </b-table>
        <br />
        <total-component :total="sumTotal" :subtotal="sumSubTotal" />
      </template>
    </filter-slot>
    <tracking-request-ncr-modal
      v-if="showModalTracking"
      @close="closeModalTracking"
      :dataNcr="dataNcr"
      :isClient="isTabClient"
    ></tracking-request-ncr-modal>

    <modal-ncr-attempts
      v-if="showAttemptsModal"
      :item="rowNcr"
      :isClient="isTabClient"
      @reload="$refs['RequestNcrTable'].refresh()"
      @close="showAttemptsModal = false"
    />
    <modal-state-legend-ncr
      v-if="showModalStateLegend"
      @hidden="showModalStateLegend = false"
      :subTabName="subTabName"
    />

    <modal-create-ncr-request
      v-if="showModalCreateNcr"
      :item="failedNcr"
      :ncrType="'MANUAL'"
      @close="closeRequestNcrModal"
    />

    <b-modal
      v-model="showFullTextModal"
      title="Description of the NCR Request"
      @hidden="closeFullTextModal"
      centered
      hide-footer
      size="sm"
    >
      <div v-html="fullText"></div>
    </b-modal>
    <ModalCollectCR
      :lead="lead"
      :isNcr="true"
      :amount="amount"
      :score_id="score_id"
      :idNcr="id_ncr"
      v-if="modalCollectCR"
      @hidden="modalCollectCR = false"
      @onCollectedPayment="closeModalCollectCR"
    />

    <payment-charge-modal
      v-if="paymentChargeOn"
      :data-charge="dataCharge"
      :title-add-charge="titleAddCharge"
      @refresh="closeModalCollectCR"
      @close="closePaymentChargeModal"
    />

    <modal-result-analysis-NCR
      v-if="showResultAnalisys"
      :params="paramsResultAnalysis"
      @closeModal="closeAnalysisNCR"
      @refreshTable="$refs['RequestNcrTable'].refresh()"
    ></modal-result-analysis-NCR>
  </b-card>
</template>

<script>
// DATA
import FilterSlot from "@/views/specialist-digital/views/updates/slot/FilterSlot.vue";
import filters from "@/views/commons/components/request-ncr/data/request-ncr.filters.js";
import fields from "@/views/commons/components/request-ncr/data/request-ncr.fields.js";

// COMPONENTS
import TrackingRequestNcrModal from "@/views/commons/components/request-ncr/views/modals/TrackingRequestNcrModal.vue";
import ModalStateLegendNcr from "@/views/commons/components/request-ncr/views/modals/ModalStateLegendNcr.vue";
import ModalNcrAttempts from "@/views/commons/components/request-ncr/views/modals/ModalNcrAttempts.vue";
import ModalCreateNcrRequest from "@/views/commons/components/request-ncr/views/modals/ModalCreateNcrRequest.vue";
import ModalCollectCR from "@/views/ce-digital/sub-modules/sales/views/leads/view/modals/ModalCollectCR.vue";
import TotalComponent from "@/views/commons/components/applications/views/components/others/TotalComponent.vue";
import PaymentChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/PaymentChargeModal.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ModalResultAnalysisNCR from "@/views/commons/components/request-ncr/views/modals/ModalResultAnalysisNCR.vue";

// SERVICES
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";

// STORE
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    FilterSlot,
    TotalComponent,
    TrackingRequestNcrModal,
    ModalStateLegendNcr,
    ModalNcrAttempts,
    ModalCreateNcrRequest,
    ModalCollectCR,
    PaymentChargeModal,
    StatusAccount,
    ModalResultAnalysisNCR,
  },

  data() {
    return {
      paymentChargeOn: false,
      dataCharge: null,
      titleAddCharge: "",
      fields: fields,
      items: [],
      typeReport: null,
      filterSlot: {
        filters: filters,
        filterSearch: {
          type: "input",
          inputType: "text",
          placeholder: "Client...",
          model: "",
        },
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        //rows
        totalRows: 0,
        startRow: 0,
        endRow: 0,
        toPage: 0,
      },
      isBusy: false,
      showModalTracking: false,
      dataNcr: null,
      subTab: "",
      showAttemptsModal: false,
      showFullTextModal: false,
      rowNcr: null,
      fullText: "",
      disabledBtn: false,
      showModalStateLegend: false,
      showModalCreateNcr: false,
      failedNcr: {
        parent_id: null,
        account: null,
        client_account_id: null,
        fullname: null,
        lead_id: null,
        module_id: null,
        program_id: null,
        card_id: null,
        used_cards: null,
        type: null,
      },
      score_id: 0,
      id_ncr: null,
      modalCollectCR: false,
      lead: {
        id: null,
        lead_name: null,
        score: null,
      },
      amount: 0,
      sumTotal: 0,
      sumSubTotal: 0,
      showResultAnalisys: false,
      paramsResultAnalysis: {},
    };
  },

  async created() {
    await this.start();
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      // client: "DebtSolutionClients/G_CLIENTS",
      getRefreshTable: "RequestNcrStore/getRefreshTable",
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
      leadDashboardRoutes: "clients-store/leadDashboardRoutes",
    }),

    visibleFields() {
      this.modifyVisibility("analysis", this.isTabLead || this.isTabClient);
      return this.fields.filter((field) => field.visible);
    },
    filterModule() {
      return this.getFilterByKey("department").model;
    },

    // get current module
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    tabName() {
      if (this.moduleId == 5) return this.$route.matched[3].meta.tabName;
      return this.$route.matched[2].meta.tabName;
    },

    isTabClient() {
      if (this.moduleId == 5)
        return this.$route.matched[3].meta.tabName == "client";
      return this.$route.matched[2].meta.tabName == "client";
    },

    isTabLead() {
      if (this.moduleId == 5)
        return this.$route.matched[3].meta.tabName == "lead";
      return this.$route.matched[2].meta.tabName == "lead";
    },

    isPendingSubTab() {
      return this.subTabName == "pending";
    },

    isFinishedSubTab() {
      return this.subTabName == "finished";
    },

    isAnnulledSubTab() {
      return this.subTabName == "annulled";
    },

    subTabName() {
      if (this.moduleId == 5) return this.$route.matched[4].meta.subTabName;
      return this.$route.matched[3].meta.subTabName;
    },

    isAutomatic() {
      return this.typeReport == 1;
    },

    isManual() {
      return this.typeReport == 2;
    },

    isFinancialApproval() {
      return [29, 25].includes(this.moduleId); // mncr // return [11, 25].includes(this.moduleId);
    },

    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },

    leadDashboardRouteName() {
      return this.leadDashboardRoutes[this.moduleId] || null;
    },
  },

  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "RequestNcrStore/CHANGE_REFRESH_TABLE",
      A_REQUEST_NCR_COUNTERS: "RequestNcrStore/A_REQUEST_NCR_COUNTERS",
    }),

    getFilterByKey(key) {
      const filter = this.filterSlot.filters.find(
        (filter) => filter.key === `${key}`
      );

      return filter;
    },

    async myProvider(ctx) {
      try {
        let params = {
          search: this.filterSlot.filterSearch.model,
          startDate: this.getFilterByKey("from").model,
          endDate: this.getFilterByKey("to").model,
          moduleId: [29, 25].includes(this.moduleId) // mncr moduleId: [11, 25].includes(this.moduleId)
            ? this.getFilterByKey("department").model
            : this.moduleId,
          entityType: this.tabName,
          reportType: this.getFilterByKey("ncr_type").model,
          subTab: this.subTab,
          status: this.getFilterByKey("ncr_status").model,
          userId:
            this.isFinancialApproval ||
            this.isSupervisor ||
            this.isChief ||
            this.isCeo
              ? this.getFilterByKey("requested_by").model
              : this.currentUser.user_id,
          obtainedBy: this.getFilterByKey("obtained_by").model,
          ...ctx,
        };
        const { data } = await NcrRequestService.getRequestNcr(params);

        this.items = data.data;

        let sumItemsAutomatic = 0;
        let sumItemsManual = 0;

        this.items.map((item) => {
          sumItemsAutomatic +=
            item.amount_automatic != null
              ? parseFloat(item.amount_automatic)
              : 0;
          sumItemsManual +=
            item.amount_manual != null ? parseFloat(item.amount_manual) : 0;
        });

        this.sumSubTotal = sumItemsAutomatic + sumItemsManual;
        this.sumTotal =
          this.items.length != 0 ? parseFloat(data.data[0].sum_total) : 0;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;
        this.filterSlot.totalRows = data.total;
        this.filterSlot.toPage = data.to;
        this.filterSlot.startRow = data.from;
        this.filterSlot.endRow = data.to;
        return this.items;
      } catch (ex) {
        return [];
      } finally {
        this.disabledBtn = false;
      }
    },

    async getAdvisorForModule(module) {
      this.getFilterByKey("requested_by").model = null;
      let params = {
        idModule: module,
      };
      const data = await NcrRequestService.getAdvisorForModule(params);
      let newAdvisors = data;
      newAdvisors.unshift({
        id: 0,
        name: "All",
      });
      this.getFilterByKey("requested_by").options = newAdvisors;
    },

    async AnnulledNcrRequest(item) {
      try {
        const { isConfirmed } = await this.showConfirmSwal(
          "Are you sure you want to annul this NCR Request?"
        );
        if (!isConfirmed) return;
        this.addPreloader();
        let params = {
          idNcrRequest: item.id,
          idUser: this.currentUser.user_id,
          typeNcr: item.type_ncr,
          clientAccountId: item.client_account_id,
          leadId: item.lead_id,
          moduleId: item.module_id,
          roleId: this.currentUser.role_id,
          scoreId: item.score_id,
        };
        const data = await NcrRequestService.annulledNcrRequest(params);
        if (data.status == 200) {
          this.$refs.RequestNcrTable.refresh();
          await this.A_REQUEST_NCR_COUNTERS(this.moduleId);
          this.showSuccessSwal("NCR Request successfully annulled");
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          error.message,
          "XIcon",
          "Something went wrong"
        );
      } finally {
        this.removePreloader();
      }
    },

    async viewPdfNcr(item) {
      try {
        this.addPreloader();
        let params = {
          file_pdf: item.file_pdf,
        };
        const { data } = await NcrRequestService.addUrlTemporaryPdfNcr(params);
        window.open(data, "_blank");
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          error.message,
          "XIcon",
          "Something went wrong"
        );
      } finally {
        this.removePreloader();
      }
    },

    reloadTable() {
      this.$refs["RequestNcrTable"].refresh();

      if (this.getFilterByKey("ncr_type").model == null && this.typeReport) {
        this.getFilterByKey("ncr_type").model = this.typeReport;
      }

      if (this.typeReport !== this.getFilterByKey("ncr_type").model) {
        this.typeReport = this.getFilterByKey("ncr_type").model;
      }
    },

    resetAllFilters() {
      this.filterSlot.filters.forEach((filter) => {
        filter.model = null;
      });
      this.filterSlot.filterSearch.model = null;
      this.typeReport = null;
      this.$refs.RequestNcrTable.refresh();
    },

    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },

    openFullTextModal(item) {
      this.showFullTextModal = true;
      this.fullText = item.obs;
    },
    closeFullTextModal() {
      this.showFullTextModal = false;
    },

    amountAutomatic() {},

    async start() {
      // *** FIELDS ***
      this.filterColumn(
        "analysis",
        this.isPendingSubTab && this.moduleId == 29
      );
      this.filterColumn("client", this.isTabClient);
      this.filterColumn("lead", this.isTabLead);
      this.filterColumn("status", !this.isAnnulledSubTab);
      this.filterColumn("departament", this.isFinancialApproval);
      this.filterColumn(
        "attempts",
        this.isPendingSubTab && this.isFinancialApproval
      );
      this.filterColumn(
        "actions",
        (this.isFinancialApproval && !this.isAnnulledSubTab) ||
          this.isFinishedSubTab
      );
      this.filterColumn("obtained_by", this.isFinishedSubTab);

      // *** FILTERS ***
      if (this.isFinancialApproval) {
        this.getFilterByKey("requested_by").options = [{ id: 0, name: "All" }];
      } else {
        this.getAdvisorForModule(this.moduleId);
      }

      this.getFilterByKey("department").visible = this.isFinancialApproval; // visible filter departaments to specialists
      this.getFilterByKey("requested_by").visible = false; // visible filter advisor

      let nameSubTab = this.$route.matched[3].meta.subTabName;
      let statusOptionsMap = {
        pending: [
          { id: 1, text: "PENDING" },
          { id: 2, text: "FAILED ATTEMPT" },
        ],
        finished: [
          { id: 3, text: "COMPLETED" },
          { id: 4, text: "FAILED" },
        ],
      };

      this.getFilterByKey("obtained_by").visible =
        this.isFinishedSubTab && this.isFinancialApproval;

      if (this.isPendingSubTab || this.isFinishedSubTab) {
        this.subTab = this.isPendingSubTab ? 1 : 2;
        this.getFilterByKey("ncr_status").visible = true;
        this.getFilterByKey("ncr_status").options =
          statusOptionsMap[nameSubTab];
      } else {
        this.subTab = 3;
        this.getFilterByKey("ncr_status").visible = false;
      }
    },

    redirectValidation(item) {
      return this.moduleId != 22 || (this.moduleId == 22 && !this.isAgent);
    },

    badgeVariant(auto, manual) {
      if (auto == 0 || manual == 0) {
        return "light-warning";
      } else {
        return "light-success";
      }
    },

    nameModule(module) {
      if (module == "CUSTOMER SERVICE REGULAR") {
        return "CREDIT EXPERTS (REGULAR)";
      } else if (module == "CUSTOMER SERVICE DIGITAL") {
        return "CREDIT EXPERTS (DIGITAL)";
      } else {
        return module;
      }
    },

    badgeColor(badge) {
      let badgeClass = {
        1: "light-secondary",
        2: "light-warning",
        3: "light-success",
        4: "light-danger",
        5: "light-secondary",
        6: "light-warning",
      };
      return badgeClass[badge];
    },

    selectTypeReport(type) {
      if (this.typeReport === type) {
        this.typeReport = null; // Deseleccionar el botón si ya esta seleccionado
        this.getFilterByKey("ncr_type").model = null;
      } else {
        this.typeReport = type; // Seleccionar el botón
        this.getFilterByKey("ncr_type").model = type;
      }
    },

    closeModalTracking() {
      this.showModalTracking = false;
    },
    openModalTracking(item) {
      this.showModalTracking = true;
      this.dataNcr = item;
    },

    openAttemptsModal(item) {
      if (item.status != "TO ANALYZE") {
        this.rowNcr = item;
        this.showAttemptsModal = true;
      }
    },
    openModalStateLegend() {
      this.showModalStateLegend = true;
    },
    openRequestNcrModal(item) {
      this.failedNcr.parent_id = item.id;
      this.failedNcr.account = item.client_account;
      this.failedNcr.client_account_id = item.client_account_id;
      this.failedNcr.fullname = item.fullname;
      this.failedNcr.lead_id = item.lead_id;
      this.failedNcr.program_id = item.program_id;
      this.failedNcr.module_id = item.module_id;
      this.failedNcr.type = this.isTabClient ? "CLIENT" : "LEAD";
      this.failedNcr.card_id = item.card_id;
      this.failedNcr.used_cards = item.used_cards;

      // finally open modal
      this.showModalCreateNcr = true;
    },
    closeRequestNcrModal() {
      // close the modal
      this.showModalCreateNcr = false;

      // reset object values
      this.failedNcr = {
        parent_id: null,
        account: null,
        client_account_id: null,
        fullname: null,
        lead_id: null,
        program_id: null,
        module_id: null,
        type: null,
        card_id: null,
        used_cards: null,
      };
    },

    async collectPayment(item) {
      if (item.is_collected == 2) return;
      if (!item.client_account_id) {
        this.amount = Number(item.amount_automatic);
        this.isNcr = item.amount_automatic;
        this.lead.id = item.lead_id;
        this.lead.lead_name = item.fullname;
        this.lead.score = item.id;
        this.modalCollectCR = true;
        this.score_id = item.score_id;
        this.id_ncr = item.id;
      } else {
        try {
          const { data } =
            await NcrRequestService.getAccountChargeForNcrRequestClient({
              request_ncr_id: item.id,
              client_account_id: item.client_account_id,
            });
          if (data && data.length > 0) {
            this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
              id: item.client_account_id,
            });
            const dataPayment = {
              id: data[0].id,
              fee: data[0].fee,
              amount: data[0].amount,
              t_charge: data[0].t_charge,
              t_id: data[0].t_id,
              ncr_request_id: data[0].ncr_request_id,
            };
            this.titleAddCharge = "ADD CHARGE";
            this.dataCharge = dataPayment;
            this.paymentChargeOn = true;
          }
        } catch (ex) {
          console.log(ex);
        }
      }
    },
    modifyVisibility(nameKey, isVisible) {
      let keyField = this.fields.findIndex((item) => item.key == nameKey);
      if (keyField != -1) {
        this.fields[keyField].visible = isVisible;
      }
    },
    closeModalCollectCR() {
      this.modalCollectCR = false;
      this.CHANGE_REFRESH_TABLE(true);
    },
    closePaymentChargeModal() {
      this.titleAddCharge = null;
      this.dataCharge = null;
      this.paymentChargeOn = false;
    },
    async generateAnalysisNCR(item) {
      if (item.status === "TO ANALYZE" || item.is_analyzed === 1) {
        if (!item.is_analyzed) {
          const confirm = await this.showConfirmSwal(
            `Are you sure you want to generate the analysis for ${item.fullname} ?`
          );
          if (!confirm.isConfirmed) return;
        }
        this.paramsResultAnalysis = {
          leadName: item.fullname,
          leadId: item.lead_id,
          clientId: item.client_account_id,
          ncrRequestId: item.id,
          scoreId: item.score_id,
          is_analyzed: item.is_analyzed,
          moduleIdNcr: item.module_id,
        };
        this.showResultAnalisys = true;
      }
    },
    closeAnalysisNCR() {
      this.showResultAnalisys = false;
    },
    colorStatusAnalysis(item) {
      if (item.number_of_attempts === 0 || item.status === "PENDING")
        return "text-secondary";
      if (item.number_of_attempts > 0 && item.is_analyzed === 0)
        return "cursor-pointer hover-me text-warning";
      if (item.is_analyzed === 1) return "cursor-pointer text-success";
    },
    isAnalyzed(item) {
      let valid;
      switch (item.status) {
        case "PENDING":
        case "TO ANALYZE":
          valid = true;
          break;
        case "COMPLETED":
          if (item.is_analyzed === 1) valid = true;
          else valid = false;
          break;
        default:
          valid = false;
          break;
      }
      return valid;
    },
  },

  watch: {
    getRefreshTable(value) {
      if (value) {
        this.$refs.RequestNcrTable.refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
    typeReport() {
      this.disabledBtn = true;
      this.$refs.RequestNcrTable.refresh();
      this.start();
    },
    filterModule(newModule) {
      if (newModule != null) {
        this.getAdvisorForModule(newModule);
      } else {
        this.getFilterByKey("requested_by").options = [{ id: 0, name: "ALL" }];
        this.getFilterByKey("requested_by").model = null;
      }
    },
  },
};
</script>

<style scoped>
.item-purple {
  background-color: #009fbd !important;
  border-color: #009fbd !important;
}

.item-magenta {
  background-color: #89375f !important;
  border-color: #89375f !important;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.custom-badge {
  position: absolute;
  top: -10px;
  right: -12px;
  font-size: 12px;
  padding: 0px 6px;
}

@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.oscillate {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
</style>
