<template>
  <div id="table__accounts__manual">
    <b-table-simple
      small
      caption-top
      responsive
      bordered
      id="detail-account-manual"
    >
      <b-thead>
        <b-tr>
          <b-th class="td-left text-right header-width col-2"></b-th>
          <b-th class="text-center col-3 v-middle">
            <span class="mr-1">TransUnion</span>
            <b-img :src="require('@/assets/images/icons/transunion.png')" />
          </b-th>
          <b-th class="text-center col-3 v-middle">
            <span class="mr-1">Experian</span>
            <b-img :src="require('@/assets/images/icons/experian.png')" />
          </b-th>
          <b-th class="text-center col-3 v-middle">
            <span class="mr-1">Equifax</span>
            <b-img :src="require('@/assets/images/icons/equifax.png')" />
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(element, indexAccount) in accountsFormat">
          <b-tr
            :class="isDarkSkin ? 'bg-dark' : 'bg-secondary-table-manual'"
            :key="indexAccount"
            v-if="!statusDone"
          >
            <b-th>
              <b-badge
                v-if="!statusDone"
                variant="light-danger"
                class="cursor-pointer ml-1"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.hover.right="'DELETE ACCOUNT'"
              >
                <feather-icon
                  icon="TrashIcon"
                  style="height: 15px; width: 15px"
                  @click="deleteAccount(indexAccount, element)"
                />
              </b-badge>
            </b-th>
            <b-th>
              <b-button
                v-if="copyBureau[indexAccount][0].length != 9 && !statusDone"
                size="sm"
                @click="copyAttachment(indexAccount, 0)"
                class="mr-1"
                variant="outline-primary"
              >
                <feather-icon icon="CopyIcon" /> Copy
              </b-button>
              <b-button
                @click="pasteAttachment(indexAccount, 0)"
                class="mr-1"
                size="sm"
                v-if="
                  bureauCopyPaste.index === indexAccount &&
                  bureauCopyPaste.bureau != 0
                "
                variant="outline-primary"
              >
                <feather-icon icon="FileTextIcon" size="16" />
                Paste</b-button
              >
              <b-button
                @click="deleteItem(indexAccount, 0)"
                class="mr-1"
                size="sm"
                v-if="copyBureau[indexAccount][0].length != 9 && !statusDone"
                variant="outline-primary"
              >
                <feather-icon icon="XIcon" size="16" />
                Clear</b-button
              >
            </b-th>
            <b-th>
              <b-button
                v-if="copyBureau[indexAccount][1].length != 9 && !statusDone"
                size="sm"
                class="mr-1"
                @click="copyAttachment(indexAccount, 1)"
                variant="outline-primary"
              >
                <feather-icon icon="CopyIcon" /> Copy
              </b-button>
              <b-button
                size="sm"
                class="mr-1"
                v-if="
                  bureauCopyPaste.index === indexAccount &&
                  bureauCopyPaste.bureau != 1
                "
                @click="pasteAttachment(indexAccount, 1)"
                variant="outline-primary"
              >
                <feather-icon icon="FileTextIcon" size="16" />
                Paste</b-button
              >
              <b-button
                @click="deleteItem(indexAccount, 1)"
                class="mr-1"
                size="sm"
                v-if="copyBureau[indexAccount][1].length != 9 && !statusDone"
                variant="outline-primary"
              >
                <feather-icon icon="XIcon" size="16" />
                Clear</b-button
              >
            </b-th>
            <b-th>
              <b-button
                v-if="copyBureau[indexAccount][2].length != 9 && !statusDone"
                size="sm"
                class="mr-1"
                @click="copyAttachment(indexAccount, 2)"
                variant="outline-primary"
              >
                <feather-icon icon="CopyIcon" /> Copy
              </b-button>
              <b-button
                @click="pasteAttachment(indexAccount, 2)"
                class="mr-1"
                size="sm"
                v-if="
                  bureauCopyPaste.index === indexAccount &&
                  bureauCopyPaste.bureau != 2
                "
                variant="outline-primary"
              >
                <feather-icon icon="FileTextIcon" size="16" />
                Paste</b-button
              >
              <b-button
                @click="deleteItem(indexAccount, 2)"
                class="mr-1"
                size="sm"
                v-if="copyBureau[indexAccount][2].length != 9 && !statusDone"
                variant="outline-primary"
              >
                <feather-icon icon="XIcon" size="16" />
                Clear</b-button
              >
            </b-th>
          </b-tr>
          <b-tr :key="indexAccount + 100 * 1999">
            <b-th>Account Name</b-th>
            <b-th colspan="3" class="text-center">
              <v-select
                v-model="element.creditor_name"
                :options="dataCreditorsDS"
                @search="onSearch"
                :disabled="statusDone"
              >
              </v-select>
            </b-th>
          </b-tr>
          <template v-for="(field, indexHeader) in element.headers">
            <b-tr
              :key="`${field.key}-${indexAccount}`"
              :style="{
                display: field.visible
                  ? field.showDetail
                    ? ''
                    : 'none'
                  : 'none',
              }"
            >
              <td>
                <template v-if="field.key === 'show_detail'">
                  <b-badge
                    @click="showDetailField(element, indexAccount, indexHeader)"
                    variant="light-primary"
                    style="cursor: pointer"
                  >
                    {{ element.showDetail ? "Hide detail" : "Show Detail" }}
                  </b-badge>
                </template>
                <template v-else>
                  <template
                    v-if="addOptions.includes(field.key) && !statusDone"
                  >
                    <div class="d-flex justify-content-between">
                      {{ field.label }}
                      <feather-icon
                        size="20"
                        @click="openModalAddAccounts(field)"
                        icon="PlusCircleIcon"
                        class="cursor-pointer text-primary"
                        v-b-tooltip.hover.top="'Add new comment'"
                      />
                    </div>
                  </template>
                  <template v-else>
                    {{ field.label }}
                  </template>
                </template>
              </td>

              <template v-for="(item, indexItem) in element.account">
                <td :key="indexItem" class="p-0">
                  <validation-observer
                    :ref="`tableForm-${indexAccount}-${indexItem}`"
                  >
                    <InputItemAccountVue
                      :field="field"
                      :item="item"
                      v-if="field.key != 'show_detail'"
                      :key="indexAccount + indexItem + 1000"
                      :disabled="statusDone"
                    >
                      <template #a_type="{ errors, field, item }">
                        <v-select
                          class="v-select-manual"
                          :class="errors[0] ? 'is-invalid' : 'valid'"
                          v-model="item[field.key].value"
                          :options="dataAccountType"
                          label="text"
                          :reduce="(option) => option.value"
                          :placeholder="field.placeholder"
                          :disabled="statusDone"
                        ></v-select>
                      </template>
                      <template #a_status="{ errors, field, item }">
                        <v-select
                          class="v-select-manual"
                          v-model="item[field.key].value"
                          :options="dataAccountStatus"
                          label="text"
                          :reduce="(option) => option.value"
                          :placeholder="field.placeholder"
                          :class="errors[0] ? 'is-invalid' : 'valid'"
                          :disabled="statusDone"
                        ></v-select>
                      </template>
                      <template #comments="{ errors, field, item }">
                        <v-select
                          multiple
                          class="v-select-manual"
                          v-model="item[field.key].value"
                          :options="dataComments"
                          label="text"
                          :reduce="(option) => option.value"
                          :placeholder="field.placeholder"
                          :class="errors[0] ? 'is-invalid' : 'valid'"
                          :disabled="statusDone"
                        ></v-select>
                      </template>
                      <template #a_type_detail="{ errors, field, item }">
                        <v-select
                          class="v-select-manual"
                          v-model="item[field.key].value"
                          :options="dataAccountTypeDetail"
                          label="text"
                          :reduce="(option) => option.value"
                          :placeholder="field.placeholder"
                          :class="errors[0] ? 'is-invalid' : 'valid'"
                          :disabled="statusDone"
                        ></v-select>
                      </template>
                    </InputItemAccountVue>
                  </validation-observer>
                </td>
              </template>
            </b-tr>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
    <ModalAddData
      v-if="rowSelected"
      :keySelected="rowSelected"
      @closeModal="rowSelected = null"
      @loadAddedOptions="loadAddedOptions"
    />
  </div>
</template>
<script>
import {
  headersManual,
  inputsRequireds,
  isEmpty,
  objectAccount,
  objectElement,
  sanitizerBureau,
  validInputs,
} from "@/views/commons/components/request-ncr/data/lead-helpers-accounts-manual.field";
import InputItemAccountVue from "./InputItemAccount.vue";
import { mapGetters, mapActions } from "vuex";
import {
  validateDateFormat,
  validateNotFutureDate,
} from "@/views/commons/components/request-ncr/views/modals/AddAccountsManual/rules/lead-manual.rules";
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";
import ModalAddData from "@/views/commons/components/request-ncr/views/modals/AddAccountsManual/ModalAddData.vue";
import DebtSolutionService from "@/views/debt-solution/services/debt-solution.service.js";
import _ from "lodash";

export default {
  name: "TableAccountsManual",
  components: {
    InputItemAccountVue,
    ModalAddData,
  },
  props: {
    creditorName: {
      type: String | null,
      required: true,
    },
    crAccountsStore: {
      type: Array,
      required: true,
    },
    statusDone: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dataCreditorsDS: [],
      addOptions: ["comments"],
      rowSelected: null,
      indexFieldShow: [],
      accounts: [],
      accountsFormat: [],
      accountsFormatCopy: [],
      requiredFields: [],
      notRequiredFields: [],
      crAccounts: [],
      dataAccountType: [],
      dataAccountTypeDetail: [],
      dataAccountStatus: [],
      dataComments: [],
      loadFirst: false,
      bureauCopyPaste: {
        index: null,
        bureau: null,
        element: null,
      },
      creditor_name: "",
      nameCreditors: [],
    };
  },
  computed: {
    copyBureau() {
      let data = [...this.requiredFields];
      const filteredData = data.map((item) => {
        return item.map((x) => x.filter((y) => y.required));
      });
      return filteredData;
    },
  },
  watch: {
    accountsFormat: {
      handler(value) {
        try {
          let keyNot = ["show_detail"];
          let elements = [];
          let accounts = [...JSON.parse(JSON.stringify([...value]))];
          this.nameCreditors = [];
          this.accountsFormatCopy = accounts;
          accounts.forEach((item, index) => {
            let x = [];
            item.account.forEach((item1, index1) => {
              let obj = sanitizerBureau(item1, true);
              x.push(obj);
            });
            elements.push(x);
          });
          this.accounts = elements;
          accounts.forEach((item, index) => {
            this.setCreditorName(item.creditor_name, index);
          });
          this.addCreditorNameAccountsFormat();
          let newAccounts = [];

          accounts.forEach((item) => {
            let itemBureau = [];
            item.account.forEach((item1, index1) => {
              let total = validInputs(item1, index1, true);
              let totalHeaders = inputsRequireds.filter(
                (x) => x.visible && !x.opcional && x.key != "a_name"
              );
              if (total === totalHeaders.length) {
                let obj = sanitizerBureau(item1, true);
                itemBureau.push(obj);
              } else {
                let obj = {};
                headersManual.forEach((ite) => {
                  if (!keyNot.includes(ite.key)) {
                    if (ite.type === "money") {
                      obj[ite.key] = "0.00";
                    } else if (ite.type === "number") {
                      obj[ite.key] = 0;
                    } else if (ite.type === "array") {
                      obj[ite.key] = "-";
                    } else {
                      obj[ite.key] = "-";
                    }
                  }
                });
                itemBureau.push(obj);
              }
            });
            newAccounts.push(itemBureau);
          });
          this.A_ACCOUNTS_FINAL(newAccounts);
        } catch (ex) {
          console.log({ ex });
        }
      },
      deep: true,
    },
    accountsFormatCopy: {
      handler(value) {
        this.getKeys("accountsFormatCopy");
      },
      deep: true,
      immediate: true,
    },
    crAccounts: {
      handler(value) {
        let arrayElements = [];
        let accountsFormat = [];
        value.forEach((element) => {
          let arrayItem = [];
          element.forEach((item, indexBureau) => {
            let keys = Object.keys(item);
            let obj = {};
            headersManual.forEach((header) => {
              if (keys.includes(header.key)) {
                let baseProperties = {
                  value: this.getValue(item, header),
                  required: false,
                };
                obj[header.key] = { ...baseProperties };
              }
            });
            arrayItem.push({ ...obj });
          });
          let creditorName = element.find(
            (x) => isEmpty(x.a_name) && x.a_name != "-"
          );
          arrayElements.push({
            ...objectElement(
              arrayItem,
              creditorName ? creditorName.a_name.toUpperCase() : ""
            ),
          });
        });
        arrayElements.forEach((item) => {
          accountsFormat.push({ ...item });
        });
        this.accountsFormat = [...accountsFormat];
        this.accountsFormatCopy = [...accountsFormat];

        if (arrayElements.length === 0 && this.crAccounts.length === 0) {
          //this.addAccount();
        }
      },
      deep: true,
      immediate: false,
    },
    nameCreditors: {
      handler(value) {
        this.addCreditorNameAccountsFormat();
      },
      deep: true,
      immediate: true,
    },
    accounts: {
      handler(value) {
        this.$emit("loadAccounts", value);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  methods: {
    ...mapActions({
      A_ACCOUNTS_FINAL: "LeadNcrManualAccountStore/A_ACCOUNTS_FINAL",
    }),
    addCreditorNameAccountsFormat() {
      this.nameCreditors.forEach((item, index) => {
        const { indexAccount, creditor_name } = item;
        try {
          this.accountsFormat[indexAccount].account.forEach((item, index) => {
            let total = validInputs(item, index, true, true);
            if (total > 0) {
              item.a_name.value = creditor_name
                ? creditor_name.toUpperCase()
                : "";
            } else {
              item.a_name.value = "";
            }
          });
        } catch (ex) {}
      });
    },
    deleteItem(idxAcc, idxItem) {
      for (let key in this.accountsFormat[idxAcc].account[idxItem]) {
        let defaultValue =
          key == "comments"
            ? []
            : ["balance", "c_limit", "h_credit"].includes(key)
            ? 0
            : "";
        this.accountsFormat[idxAcc].account[idxItem][key].value = defaultValue;
      }
    },
    getCreditorName(indexAccount) {
      let x = this.accountsFormat[indexAccount].creditor_name;
      return x;
    },
    getValue(item, header) {
      if (header.type === "array") {
        return item[header.key] === "-" ? [] : item[header.key].split(",");
      } else if (header.type === "number") {
        return Number(item[header.key]) === 0 ? "" : item[header.key];
      } else {
        return item[header.key] === "-" ? "" : item[header.key];
      }
    },
    async addAccount() {
      try {
        if (!isEmpty(this.creditorName)) throw new Error("Add creditor name");
        await this.validateItem();
        await this.observerValidate();
        this.accountsFormat.unshift({ ...objectAccount(this.creditorName) });
        this.$emit("update:creditorName", "");
      } catch (ex) {
        throw new Error(ex.message);
      }
    },
    async verificateItem() {
      try {
        await this.validateItem();
        await this.observerValidate();
      } catch (ex) {
        throw new Error(ex.message);
      }
    },
    async deleteAccount(indexAccount, element) {
      try {
        const response = await this.showConfirmSwal(
          `Are you sure you want to delete the account of ${element.creditor_name}`
        );
        if (response.isConfirmed) {
          this.accountsFormat.splice(indexAccount, 1);
          this.accounts.splice(indexAccount, 1);
          // this.nameCreditors.splice(indexAccount, 1);
          this.$emit("deleteAccounts", this.accounts);
        }
      } catch (ex) {
        console.log(ex.message);
      }
    },
    showDetailField(element, indexAccount, indexHeader) {
      element.showDetail = !element.showDetail;
      let x = [];
      element.headers.forEach((item, key) => {
        if (key != indexHeader) {
          if (!item.showDetail) {
            x.push(key);
            this.indexFieldShow[indexAccount] = x;
          }
          if (
            this.indexFieldShow[indexAccount] &&
            this.indexFieldShow[indexAccount].includes(key)
          ) {
            item.showDetail = element.showDetail;
          }
        }
      });
    },
    async observerValidate() {
      let in_valids = [];
      let validatioArray = [];
      this.accountsFormat.forEach((ac, indexAccount) => {
        ac.account.forEach((item, indexItem) => {
          validatioArray.push(`tableForm-${indexAccount}-${indexItem}`);
        });
      });

      validatioArray.forEach((item) => {
        const validation = this.$refs[item];
        validation.forEach((observer) => {
          let x = observer.validate();
          in_valids.push(x);
        });
      });
      let totalInvalid = await Promise.all(in_valids).then((result) => {
        return result.filter((x) => x === false);
      });
      if (totalInvalid.length > 0) {
        throw new Error("Please fill in the required fields");
      }
    },
    async getKeys(element) {
      this.requiredFields = [];
      this[element].forEach((ac, indexAc) => {
        let arrayAc = [];
        ac.account.forEach((item, index) => {
          let cont = 1;
          let arrayItem = [];
          let keyArrays = Object.keys(item);
          keyArrays.forEach((k, i) => {
            let header = ac.headers.find((x) => x.key === k && !x.opcional);
            if (header) {
              this[element][indexAc].account[index][k].required = false;
              switch (header.type) {
                case "money": {
                  if (item[k].value === "" || Number(item[k].value) === 0) {
                    arrayItem.push({
                      required: true,
                      prop: k,
                      opcional: header.opcional,
                    });
                  }
                  break;
                }
                case "string": {
                  if (item[k].value === "" || item[k].value === null) {
                    arrayItem.push({
                      required: true,
                      prop: k,
                      opcional: header.opcional,
                    });
                  }
                  break;
                }
                case "number": {
                  if (item[k].value === "") {
                    arrayItem.push({
                      required: true,
                      prop: k,
                      opcional: header.opcional,
                    });
                  }
                  break;
                }
                case "date": {
                  if (
                    item[k].value === "" ||
                    !validateNotFutureDate(item[k].value)
                  ) {
                    arrayItem.push({
                      required: true,
                      prop: k,
                      opcional: header.opcional,
                    });
                  }
                  break;
                }
                case "array": {
                  if (item[k].value.length === 0) {
                    arrayItem.push({
                      required: true,
                      prop: k,
                      opcional: header.opcional,
                    });
                  }
                  break;
                }
              }
            } else {
              this[element][indexAc].account[index][k].required = false;
              let headerNot = ac.headers.find((x) => x.key === k && x.opcional);
              if (headerNot) {
                switch (headerNot.type) {
                  case "money": {
                    if (item[k].value != "" && Number(item[k].value) != 0) {
                      arrayItem.push({
                        required: false,
                        prop: k,
                        opcional: headerNot.opcional,
                      });
                    }
                    break;
                  }
                  case "number": {
                    if (item[k].value != "") {
                      arrayItem.push({
                        required: false,
                        prop: k,
                        opcional: headerNot.opcional,
                      });
                    }
                    break;
                  }
                  case "string": {
                    if (item[k].value != "") {
                      arrayItem.push({
                        required: false,
                        prop: k,
                        opcional: headerNot.opcional,
                      });
                    }
                    break;
                  }
                  case "date": {
                    if (
                      item[k].value != "" &&
                      !validateNotFutureDate(item[k].value)
                    ) {
                      arrayItem.push({
                        required: false,
                        prop: k,
                        opcional: headerNot.opcional,
                      });
                    }
                    break;
                  }
                  case "array": {
                    if (item[k].value.length > 0) {
                      arrayItem.push({
                        required: false,
                        prop: k,
                        opcional: headerNot.opcional,
                      });
                    }
                    break;
                  }
                }
              }
            }
          });
          arrayAc.push(arrayItem);
        });
        this.requiredFields.push(arrayAc);
      });
    },
    matricesEqual(arr, position) {
      const longitud = arr[position].length;
      return arr.every((subArray) => subArray.length === longitud);
    },
    async validateItem() {
      await this.getKeys("accountsFormat");
      let headers = headersManual.filter(
        (x) => !x.opcional && x.type != "boolean"
      );
      this.requiredFields.forEach((item, index) => {
        let total = item
          .map((e) => e.filter((y) => !y.opcional).length > 0)
          .filter((i) => i).length;
        if (total != 0) {
          let missing = item
            .map((it, ind) => this.matricesEqual(item, ind))
            .filter((x) => x === true).length;
          if (missing === 3) {
            throw new Error("Please fill out at least one bureau");
          } else {
            let bureaus = [];
            item.forEach((c, i) => {
              let itemRequireds = c.filter((x) => x.required).length;
              let itemNotRequireds = c.filter((x) => !x.required).length;
              if (itemNotRequireds === 0) {
                if (c.length > 0 && c.length < headers.length) {
                  bureaus.push({
                    bureau: i,
                    missing: c,
                  });
                }
              } else {
                bureaus.push({
                  bureau: i,
                  missing: c,
                });
              }
            });
            bureaus.forEach((item, indexBureau) => {
              item.missing.forEach((f) => {
                this.accountsFormat[index].account[item.bureau][
                  f.prop
                ].required = true;
              });
            });
          }
        }
      });
    },
    copyAttachment(index, bureau) {
      this.bureauCopyPaste.bureau = bureau;
      this.bureauCopyPaste.index = index;
      this.bureauCopyPaste.element = this.accountsFormat[index].account[bureau];
    },
    pasteAttachment(index, bureau) {
      let account = this.accountsFormat[index].account[bureau];
      let element = this.bureauCopyPaste.element;
      for (let key in account) {
        if (key === "a_name") {
          this.accountsFormat[index].account[bureau][key] = { ...element[key] };
        } else {
          this.accountsFormat[index].account[bureau][key] = { ...element[key] };
        }
      }
    },
    async getDataAccountType() {
      try {
        const data = await NcrRequestService.getAccountTypes({
          search: "",
        });
        this.dataAccountType = data;
      } catch (ex) {}
    },
    async getDataAccountTypeDetail() {
      try {
        const data = await NcrRequestService.getAccountTypeDetails({
          search: "",
        });
        this.dataAccountTypeDetail = data;
      } catch (ex) {}
    },
    async getDataAccountStatus() {
      try {
        const data = await NcrRequestService.getAccountStatuses({
          search: "",
        });
        this.dataAccountStatus = data;
      } catch (ex) {}
    },
    async getDataAccountComments() {
      try {
        const data = await NcrRequestService.getComments({
          search: "",
        });
        this.dataComments = data;
      } catch (ex) {}
    },
    openModalAddAccounts(field) {
      this.rowSelected = field;
    },
    async loadAddedOptions(rowSelected) {
      try {
        switch (rowSelected.key) {
          case "a_type":
            await this.getDataAccountType();
          case "a_type_detail":
            await this.getDataAccountTypeDetail();
          case "comments":
            await this.getDataAccountComments();
          case "a_status":
            await this.getDataAccountStatus();
          default:
            return "";
        }
      } catch (ex) {}
    },
    setCreditorName(creditor_name, indexAccount) {
      // let creditor_name = $event.target.value;
      let indexExists = this.nameCreditors.findIndex(
        (x) => x.indexAccount === indexAccount
      );
      if (indexExists != -1) {
        this.nameCreditors[indexExists].creditor_name = creditor_name;
      } else {
        this.nameCreditors.push({
          indexAccount,
          creditor_name,
        });
      }
    },
    onSearch(search, loading) {
      if (search.length > 0) {
        loading(true);
        this.searchCreditors(loading, search, this);
      } else {
        this.dataCreditorsDS = [];
      }
    },
    searchCreditors: _.debounce(async function (loading, search, vm) {
      let creditors = [];
      const data = await DebtSolutionService.getCreditorsDS({
        q: search,
      });
      data.forEach((item) => {
        creditors.push(item.value);
      });
      vm.dataCreditorsDS = creditors.filter(
        (item, index) => creditors.indexOf(item) === index
      );
      loading(false);
    }, 100),
    async searchCreditorDS(search) {
      try {
        let creditors = [];
        const data = await DebtSolutionService.getCreditorsDS({
          q: search,
        });
        data.forEach((item) => {
          creditors.push(item.value);
        });
        this.dataCreditorsDS = creditors;
      } catch (ex) {}
    },
  },
  async mounted() {
    this.$nextTick(() => {
      Promise.all([
        this.getDataAccountType(),
        this.getDataAccountTypeDetail(),
        this.getDataAccountStatus(),
        this.getDataAccountComments(),
      ]);
      this.crAccounts = this.crAccountsStore;
    });
  },
};
</script>
<style lang="scss">
#table__accounts__manual {
  .v-select-manual .vs__dropdown-toggle {
    border: none !important;
    border-radius: 0 !important;
    /* padding: 0!important; */
    /* height: 2.242rem!important;
  padding-left: 3px!important; */
  }
  .v-select-manual .vs__dropdown-menu {
    z-index: 2000;
  }
  .v-select-manual.is-invalid .vs__dropdown-toggle {
    border: 1px solid #fc424a !important;
  }

  .bg-secondary-table-manual {
    background-color: #82868b1f !important;
  }
}
</style>
