import inquiryClaimService from "@/views/commons/components/claims_v2/views/claim-inquiries/services/inquiry-claim.service";

const filters = [
  {
    key: "from",
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    nameFilter: "from",
  },
  {
    key: "to",
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    nameFilter: "to",
  },
  {
    key: "department",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Departament",
    model: null,
    options: [
      { id: 2, text: "CRM" },
      { id: 3, text: "Business" },
      { id: 5, text: "Debt Solution" },
      { id: 6, text: "Credit Experts" },
      { id: 7, text: "Boost Credit" },
      { id: 8, text: "Tax Research" },
      { id: 10, text: "Court Info" },
      { id: 11, text: "Specialist" },
      { id: 15, text: "Metamedia" },
      { id: 18, text: "Quality" },
      { id: 20, text: "Connection" },
      { id: 22, text: "Customer Service Digital" },
      { id: 23, text: "Financial" },
      { id: 26, text: "Credit Experts - Sales" },
      { id: 28, text: "Specialist Analyst" },
      { id: 29, text: "Specialist Financial" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 12,
    visible: false,
    nameFilter: "module",
  },
  {
    key: "ncr_status",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status",
    model: null,
    options: [],
    reduce: "id",
    selectText: "text",
    cols: 12,
    visible: true,
    nameFilter: "status",
  },
  {
    key: "requested_by",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Requested by",
    model: null,
    options: [],
    reduce: "id",
    selectText: "name",
    cols: 12,
    visible: false,
    nameFilter: "advisor",
  },
  {
    key: "ncr_type",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Type",
    model: null,
    options: [
      { id: 1, name: "AUTOMATIC" },
      { id: 2, name: "MANUAL" },
    ],
    reduce: "id",
    selectText: "name",
    cols: 12,
    nameFilter: "type",
  },

  {
    key: "obtained_by",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Obtained by",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
    department: "Specialists",
    visible: false,
  },
];

const processFilters = async () => {
  const { specialists } = await inquiryClaimService.getUsersForInquiries();
  filters[6].options = specialists;
};

processFilters();

export default filters;
