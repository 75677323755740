<template>
    <b-modal v-model="showModal" :title="'ADD ' + keyName" @hidden="closeModal" no-close-on-backdrop>
      <b-form-group
        class="mb-0"
        :label="keyName"
      >
        <b-form-textarea v-model="valueData" />
      </b-form-group>
      <template #modal-footer>
        <b-button
          variant="success"
          @click="saveData()"
        >
          SAVE
          <feather-icon icon="SaveIcon" />
        </b-button>
      </template>
    </b-modal>
</template>
<script>
import NcrRequestServices from "@/views/commons/components/request-ncr/services/ncr-request.service.js"
import { mapGetters } from "vuex"
export default {
    props: {
        keySelected: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            valueData: null,
            showModal: true,
            type:""
        }
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser"
        }),
        keyName(){
            switch(this.keySelected.key){
                case 'a_type':
                    this.type = "Type";
                    return 'TYPE ACCOUNT'
                case 'a_type_detail':
                    this.type = "Type Detail";
                    return 'ACCOUNT TYPE DETAIL'
                case 'comments':
                    this.type = "Comments";
                    return 'COMMENTS'
                case 'a_status':
                    this.type = "Status";
                    return 'ACCOUNT STATUS'
                default:
                    return ''
            }
        }
    },
    methods: {
        async saveData(){
            const confirm = await this.showConfirmSwal();
            if(!confirm.isConfirmed) return;

            const params = {
                type: this.type,
                key: this.keySelected.key,
                value: this.valueData,
                userId: this.currentUser.user_id
            }
            try {
                this.addPreloader();
                const data = await NcrRequestServices.saveAdditionalOption(params);
                if(data.condition){
                    this.showToast(
                        data.condition == 'success' ? 'success' : 'warning',
                        "top-right",
                        data.condition == 'success' ? 'Success!' : "Warning!",
                        data.condition == 'success' ? 'CheckCircleIcon' : 'AlertTriangleIcon',
                        data.message
                    );
                    if(data.condition == 'success') {
                        this.$emit("loadAddedOptions",this.keySelected);
                        this.closeModal();
                    };
                }
            } catch (error) {
                this.showErrorSwal();
            } finally {
                this.removePreloader();
            }
        },
        closeModal(){
            this.$emit("closeModal");
        },
    }
}
</script>